import React from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'

const InputWrapper = styled.div`
   border: 1px solid #DADEE4;
    width: 100%;
    border-radius: 45px;
    padding: 10px 15px;
    outline: none;
    background-color: #fff;

    input::placeholder {
        color: #000;
        opacity: 0.4;
        font-size: 14px;
        font-weight: 400;
    }

`

const CustomInut = styled.input`
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
`

const NumberItem = styled.span`
    color:#7D8592;
    font-size: 14px;
`
const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`

const PhoneInput = ({handleInputChange, PersonData}) => {
    return (
        <>
            <LabelStyle>
                Telefonnummer
            </LabelStyle>
            <InputWrapper className='telInputWrapper'>
                <Grid container textAlign={'left'} alignItems={'center'} columnSpacing={1}>
                    <Grid item xs={'auto'}>
                        <NumberItem>
                            +41
                        </NumberItem>
                    </Grid>
                    <Grid item xs>
                        <CustomInut
                            type="tel"
                            pattern="^(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91)(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                            minLength={9} maxLength={9}
                            name={'tel'}
                            placeholder={'Telefonnummer'}
                            style={{ border: "none", outline: "none" }}
                            required
                            onChange={e => handleInputChange(e)}
                            value={PersonData.tel}
                        />
                    </Grid>
                </Grid>
            </InputWrapper>
        </>

    )
}

export default PhoneInput