import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as Restart } from '../assets/images/chat/restartmodal.svg';
import { ReactComponent as Close } from '../assets/images/chat/closemodal.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "545px",
    backgroundColor: "#FFFFFF",
    color: '#000',
    p: 4,
    borderRadius: "8px"
};

const BoxWrapper = styled.div`
    .MuiBox-root {
        padding:30px;
    }

    @media (max-width: 899.98px) {
        .MuiBox-root {
           max-width:390px;
         
        }
    }

    @media (max-width: 599.98px) { 
        .MuiBox-root {
            max-width: calc(100% - 1.5rem);
            padding:30px 15px;
        }
    }
`

const ModalBox = styled(Modal)`
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
        backdrop-filter: blur(5px);
    }

    @media (max-width: 899.98px) {
        .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
            backdrop-filter: blur(5px);
        }
    }

    @media (max-width: 599.98px) { 
        .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
            backdrop-filter: blur(5px);
        }
    }
`

const WhiteButton = styled.button`
    border:1px solid #49C0B2;
    border-radius:8px;
    padding: 14px 20px;
    color: #49C0B2;
    background-color: #FFFFFF;
    width:100%;

    @media (max-width: 899.98px) {
        padding: 10px 15px;
    }

    @media (max-width: 599.98px) { 
        padding: 10px 15px;
    }
`

const GreenButton = styled.button`
    background-color: #49C0B2;
    border-radius:8px;
    padding: 14px 20px;
    color: #FFFFFF;
    border:1px solid #49C0B2;
    width:100%;

    @media (max-width: 899.98px) {
        padding: 10px 15px;
    }

    @media (max-width: 599.98px) { 
        padding: 10px 15px;
    }
`

const Title = styled.span`
    display:block;
    font-size:20px;
    font-weight:600;
    line-height:25.2px;
    color: #54595E;
    
    @media (max-width: 899.98px) {
        font-size:15px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
    }
`

const Desc = styled.span`
    display:block;
    font-size:14px;
    font-weight:400;
    line-height:22px;   
    color:#54595E99;

    @media (max-width: 899.98px) {
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
    }
`

const CloseWrapper = styled.div`
    position:absolute;
    top:10px;
    right:10px;

    @media (max-width: 899.98px) {
        top:8px;
        right:8px;
    }

    @media (max-width: 599.98px) { 
        top:8px;
        right:8px;
    }
`

export default function StartOverModal({
    open,
    handleClose,
    cleardata
}) {
    return (
        <div>
            <ModalBox
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <BoxWrapper>
                    <Box sx={style}>
                        <Grid container rowSpacing={3} >
                            <Grid item xs={12}>
                                <Grid container columnSpacing={2} >
                                    <Grid item xs={'auto'}>
                                        <Restart style={{ background: '#F5F5F5', borderRadius: '8px' }} />
                                    </Grid>
                                    <Grid item xs>
                                        <Grid container flexDirection={'column'} rowSpacing={1}>
                                            <Grid item>
                                                <Title>
                                                    Sind Sie sicher, dass Sie von vorne beginnen möchten?
                                                </Title>
                                            </Grid>
                                            <Grid item>
                                                <Desc>
                                                    Dadurch werden alle Ihre Daten zurückgesetzt
                                                </Desc>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent={'center'} columnSpacing={2} rowSpacing={0}>
                                    <Grid item xs>
                                        <WhiteButton type="button" onClick={handleClose}>
                                            Nein, abbrechen
                                        </WhiteButton>
                                    </Grid>
                                    <Grid item xs>
                                        <GreenButton type="button" onClick={cleardata}>
                                            Ja, bestätigen
                                        </GreenButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CloseWrapper>
                            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
                        </CloseWrapper>
                    </Box>
                </BoxWrapper>
            </ModalBox>
        </div>
    );
}