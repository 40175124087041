import React from 'react'
import styled from 'styled-components'


const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`


function Label({ children }) {
    return (
        <LabelStyle>
            {children}
        </LabelStyle>
    )
}

export default Label