import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Mark } from '../assets/images/chat/checkboxMark.svg'

const CheckBoxWrapper = styled.div`
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 1px solid #E7EEF4;
    border-radius: 8px;
    display: grid;
    place-items: center;

    svg {
        visibility: hidden;
    }
   
`

const LabelWrapper = styled.label`
    cursor: pointer;
    position: relative;

    input:checked ~ ${CheckBoxWrapper} svg {
        visibility: visible;
    }

    input {
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: -1;
    }

    input:focus:invalid ~ ${CheckBoxWrapper} {
        border: 1px solid #F43F5E;
    }

`


function CustomCheckbox({ onChange, name, value, checked = false, required }) {

    return (
        <LabelWrapper>
            <input
                required={required}
                type="checkbox"
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
                onInvalid={e => { e.target.setCustomValidity(' ') }}
                onInput={e => { e.target.setCustomValidity('') }}
            />
            <CheckBoxWrapper>
                <Mark />
            </CheckBoxWrapper>
        </LabelWrapper>
    )
}


export default CustomCheckbox
