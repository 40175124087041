import React, { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as MenuIcon } from '../assets/images/home/navigation.svg';

const HamburgerMenu = () => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleBodyScroll = () => {
            document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        };
        handleBodyScroll();
    }, [isOpen]);
    
    const closeMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Menu isOpen={isOpen} onStateChange={state => setIsOpen(state.isOpen)} width={'100%'} height={"100vh"} right customBurgerIcon={ <MenuIcon /> } >
            <a className="menu-item" href="#Wieesfunktioniert" onClick={closeMenu} >Wie es funktioniert</a>
            <a className="menu-item" href="#Lösungen" onClick={closeMenu}>Lösungen</a>
            <a className="menu-item" href="#Überuns" onClick={closeMenu}>Über uns</a>
            {/* <a className="menu-item" href="#Kundenbewertungen" onClick={closeMenu} >Kundenbewertungen</a> */}
        </Menu>
    );
};

export default HamburgerMenu;