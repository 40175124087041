import React from 'react'
import { Grid } from '@mui/material';
import styled from 'styled-components';
import CustomContainer from '../../components/Container';
import Title from '../../components/Title';
import SectionBreaker from '../../components/SectionBreaker';
import useWindowDimensions from '../../hooks/getFunctionDimensions';
import SectionField from '../../components/SectionField';
import { ReactComponent as Entdecken1 } from '../../assets/images/home/switchIcon.svg';
import { ReactComponent as Zoom } from '../../assets/images/home/zoomIcon.svg';
import { ReactComponent as Entdecken2 } from '../../assets/images/home/arrowsIcon.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as Test } from '../../assets/images/home/test.svg';
import { EffectCards } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import '../../Style/style.css';
import ChatSlider from '../../components/ChatSlider';

const Greentext = styled.p`
  padding:10px 0;
  font-size: 18px;
  font-weight: 600;
  lin-height:18px;
  color: #47BEB9;
  text-align:left;
`;


const TitleWrapper = styled.div`
    width:100%;
    max-width: 800px;
    @media (max-width: 1440px) { 
        max-width: 700px;
    }
`;



const SectionFive = () => {


    const { width } = useWindowDimensions()

    return (
        <SectionBreaker>
            <CustomContainer>
                <Grid container >
                    <Grid item>
                        <Greentext>
                            Lorem
                        </Greentext>
                        <TitleWrapper>
                            <Title title={'Ihr Weg zur optimalen Krankenversicherung mit Insulana'} />
                        </TitleWrapper>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container flexDirection={'row'} alignItems={"center"} pt={width < 899.99 && 7} columnSpacing={10} >
                            {
                                width > 899.99 ? (
                                    <>
                                        <Grid item xs={12} lg={6} margin={'auto'}>
                                            <Swiper
                                                effect={'cards'}
                                                grabCursor={true}
                                                pagination={true}
                                                modules={[EffectCards, Pagination]}
                                                className="chatSwiper"
                                            >
                                                <SwiperSlide><ChatSlider svg={<Test />} /></SwiperSlide>
                                                <SwiperSlide><ChatSlider svg={<Test />} /></SwiperSlide>
                                            </Swiper>
                                        </Grid>
                                        <Grid xs={12} lg={6} item>
                                            <Grid container flexDirection={"column"} rowSpacing={5}>
                                                <Grid item>
                                                    <SectionField svg={<Zoom />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                                <Grid item>
                                                    <SectionField svg={<Entdecken1 />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                                <Grid item>
                                                    <SectionField svg={<Entdecken2 />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid xs={12} lg={6} item>
                                            <Grid container flexDirection={"column"} rowSpacing={5}>
                                                <Grid item>
                                                    <SectionField svg={<Zoom />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                                <Grid item>
                                                    <SectionField svg={<Entdecken1 />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                                <Grid item>
                                                    <SectionField svg={<Entdecken2 />} title={'Entdecken'} desc={'Lorem ipsum dolor sit amet consectetur. Vehicula ut elit ipsum commodo sagittis etiam viverra. Tellus risus neque ornare volutpat volutpat ultricies mit rhoncus in. '} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={6} margin={'auto'}>
                                            <Swiper
                                                effect={'cards'}
                                                grabCursor={true}
                                                pagination={true}
                                                modules={[EffectCards]}
                                                className="chatSwiper"
                                            >
                                                <SwiperSlide><ChatSlider svg={<Test />} /></SwiperSlide>
                                                <SwiperSlide><ChatSlider svg={<Test />} /></SwiperSlide>
                                            </Swiper>
                                        </Grid>
                                    </>
                                )
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </CustomContainer >
        </SectionBreaker >
    )
}

export default SectionFive