import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import FileUploadInput from '../../../components/FileUploadInput'
import Label from '../../../components/Label'
import styled from 'styled-components'
import { ReactComponent as Reisen } from '../../../assets/images/chat/reisepass.svg'
import { ReactComponent as Ausweis } from '../../../assets/images/chat/ausweis.svg'
import { ReactComponent as AusweisFront } from '../../../assets/images/chat/ausweisfront.svg'
import { ReactComponent as AusweisBack } from '../../../assets/images/chat/ausweisback.svg'
import FileUploadInputAuswies from '../../../components/FileUploadInputAuswies'


const Button = styled.button`
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    color: #050301;
    width: 100%;
    aspect-ratio: 16/8;

    @media (max-width: 899.98px) {
        font-size: 14px;
        svg {
            width: 35px;
            height: auto;
        }
    }   
`
const HintText = styled.span`
    color: #C7C7C7;   
    font-size: 12px;

`

function Step6Files({ insurance, setPersonData, PersonData }) {


    const CheckIfImageExists = (name) => {
        try {
            require(`../../../assets/images/insuranceImgs/${name}.svg`)
            return true
        } catch (error) {
            return false
        }
    }




    const [FileType, setFileType] = useState(false)

    return (
        insurance && (
            <>
                <Box textAlign={'center'} pb={3}>
                    {
                        CheckIfImageExists(insurance.name)
                            ? <img className='bigInsuranceImg'
                                src={
                                    require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                                }
                                alt={insurance.name}
                            />
                            :
                            <span className='bigInsurancePrice'>{insurance.name}</span>

                    }
                </Box>
                <Box textAlign={'center'} pb={5}>
                    <Grid container alignItems={'flex-end'} justifyContent={'center'}>
                        <div className="col-auto">
                            <Grid item xs={'auto'}>
                                <span className='bigInsurancePrice'>{insurance.price.toFixed(2)}</span>
                            </Grid>
                        </div>
                        <Grid item xs={'auto'}>
                            <span className='bigInsurancePriceCHF'>CHF</span>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container rowSpacing={3}>
                    {
                        !FileType && (
                            <Grid item xs={12}>
                                <Label>
                                    Reisepass/Ausweis
                                </Label>
                                <Grid container columnSpacing={3} rowSpacing={1}>
                                    <Grid item xs={6}>
                                        <Button onClick={() => { setFileType('Reisepass') }}>
                                            <Grid container rowSpacing={0}>
                                                <Grid item xs={12}>
                                                    <Reisen />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span>Reisepass</span>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={() => { setFileType('Ausweis') }}>
                                            <Grid container rowSpacing={0}>
                                                <Grid item xs={12}>
                                                    <Ausweis />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span>Ausweis</span>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HintText>
                                            Bitte wählen Sie aus, welches Sie hochladen möchten.
                                        </HintText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }

                    <div style={{ display: FileType === 'Ausweis' ? 'block' : 'none' }}>

                        <Grid container columnSpacing={2} rowSpacing={1} alignItems={"end"}>
                            <Grid item xs={12} sm={6}>
                                <FileUploadInputAuswies
                                    greentext={'Vorderseite'}
                                    svg={<AusweisBack />}
                                    required={(FileType === 'Ausweis' || FileType === false) ? true : undefined}
                                    label={'Ausweis'}
                                    name={'id_front'}
                                    setPersonData={setPersonData}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FileUploadInputAuswies
                                    greentext={'Rückseite'}
                                    svg={<AusweisFront />}
                                    required={(FileType === 'Ausweis' || FileType === false) ? true : undefined}

                                    name={'id_back'}
                                    setPersonData={setPersonData}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <Grid item xs={12} style={{ display: FileType === 'Reisepass' ? 'block' : 'none' }}>
                        <FileUploadInput
                            required={(FileType === 'Reisepass' || FileType === false) ? true : undefined}
                            label={'Reisepass'}
                            name={'id_front'}
                            setPersonData={setPersonData}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FileUploadInput
                            required={true}
                            label={'Vertrag'}
                            name={'contract'}
                            setPersonData={setPersonData}

                        />
                    </Grid>

                </Grid>

            </>
        )

    )
}

export default Step6Files