import React, { useEffect, useState } from 'react'
import '../../assets/css/chat.css'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import axios from 'axios'
import PrimaryButton from '../../components/PrimaryButton'
import { Box, Grid } from '@mui/material'
import { ReactComponent as AddIcon } from '../../assets/images/chat/plusperson.svg'
import { ReactComponent as ContinueIcon } from '../../assets/images/chat/continue.svg'
import { ReactComponent as BackArrow } from '../../assets/images/chat/backarrow.svg'
import { ReactComponent as Reset } from '../../assets/images/chat/reset.svg'
import styled from 'styled-components';
import StartOverModal from '../../components/StartOverModal'
import Step6 from './Steps/Step6'
import useWindowDimensions from '../../hooks/getFunctionDimensions'
import Step7Signature from './Steps/Step7Signature'
import Step6Files from './Steps/Step6Files'
import ErrorBox from '../../components/ErrorBox'
import Step8ThankU from './Steps/Step8ThankU'


const Button = styled.button`
    border:none;
    background-color:transparent;
    color: #49BFB3;
    font-size:14px;
    line-height:15px;
    font-weight:700;
`

const ErrorBoxWrapper = styled.div`
    display: none;
    padding-top: 1rem;
`

const CustomForm = styled.form`
    &:invalid ${ErrorBoxWrapper} {
        display: block;
    }

    input:invalid {
        border-color: ${props => props.$triedSubmit && '#F43F5E'};
    } 

    
`


function Steps({ SelectedInsurance, setSelectedInsurance, setIsChatShown }) {


    const { width } = useWindowDimensions();

    const [persons, setPersons] = useState([{
        id: 1,
    }])





    const [personId, setPersonId] = useState(1);

    const [Expanded, setExpanded] = useState(persons.length === 1 ? `panel1` : false);

    const AddPerson = async (event) => {
        const maxId = Math.max(...persons.map(person => person.id));
        // Generate a new ID by incrementing the maximum ID
        const newPersonId = maxId + 1;

        setPersonId(newPersonId);
        setPersons(persons => [...persons, { id: newPersonId }]);
        setExpanded(`panel${newPersonId}`);
    }

    const [Step, setStep] = useState(1)

    const [InsuranceAndPrice, setInsuranceAndPrice] = useState(null)

    const [InsuranceData, setInsuranceData] = useState({
        franchise: "2500",
        accident: "Yes",
        model: '',
        outpatientInsurance: '',
        hospitalInsurance: '',
        dentalInsurance: '',
    });

    const [Insurances, setInsurances] = useState(null)
    const [BirthYear, setBirthYear] = useState([])

    const [PersonData, setPersonData] = useState({
        gender: "Male",
        jahrgang: "",
        plz: "",
        plz1: "",
        nationalitat: "CH",
        signature: "",
        full_name: ''
    });

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const SubmitFirstStepForm = async (ev) => {
        ev.preventDefault();
        setTriedSubmit(false)
        setIsLoading(true)
        if (PersonData.plz1 && typeof PersonData.plz1 === 'string') {
            var plz = PersonData.plz1.split(' ');
            var birthyear = [];

            for (var i = 1; i <= personId; i++) {
                var name = `jahrgang${i}`;


                birthyear.push(parseInt(PersonData[name]));

            }

            setBirthYear(birthyear);

            await axios.get(`https://node.kutiza.com/krankenkasse/getGrund/${plz[0]}/${plz[1]}/${birthyear}`)
                .then((response) => {
                    setInsurances(response.data);
                    setIsLoading(false)
                    setStep(Step + 1);
                })
                .catch((error) => {
                    console.error('Error fetching insurance data:', error);
                });
        } else {
            console.error('Postal code (plz1) is not defined or not a string');
        }
    };



    const SubmitSevenStepForm = async (ev) => {
        ev.preventDefault();
        setTriedSubmit(false)
        setIsLoading(true)
        var PersonData1 = new FormData();

        Object.entries(PersonData).forEach(([key, value]) => {
            PersonData1.append(key, value);
        });

        PersonData1.append('model', InsuranceData.model)
        PersonData1.append('franchise', InsuranceData.franchise)

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        await axios.post("https://node.insulana.ch/store", PersonData1, config).then(() => {
            setIsLoading(false)
            setStep(Step + 1);
        })

    };


    useEffect(() => {

        if (Insurances && InsuranceAndPrice) {

            let splitInsu = InsuranceAndPrice.split('%')

            let insu = Insurances.final_data.filter(insurance => (insurance.id === parseInt(splitInsu[1])));
            setSelectedInsurance(insu[0])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Insurances, InsuranceAndPrice])



    useEffect(() => {

        const UpdatePrice = async () => {
            var plz = PersonData.plz1.split(' ')
            var accidentValue = InsuranceData.accident === "Yes" ? 'MIT-UNF' : 'OHN-UNF'

            let fullAcidentValue = '';
            let fullFranchise = '';

            for (let i = 0; i < BirthYear.length; i++) {

                fullAcidentValue = fullAcidentValue + accidentValue + ','
                fullFranchise = fullFranchise + InsuranceData.franchise + ','
            }


            const fullLink = `${fullAcidentValue}/${InsuranceData.tariftyp}/${InsuranceData.model}`

            await axios.get(`https://node.kutiza.com/krankenkasse/getGrund/${plz[0]}/${plz[1]}/${BirthYear}/${fullFranchise}/${Step >= 4 ? fullLink : ''}`).then((response) => {
                setInsurances(response.data)
            })

        }

        if (Step >= 4) {
            UpdatePrice()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InsuranceData])

    const [Models, setModels] = useState([])
    const [AvailableModels, setAvailableModels] = useState([])
    const [IsLoading, setIsLoading] = useState(false)

    const GetModel = async () => {

        var plz = PersonData.plz1.split(' ')
        setIsLoading(true)
        await axios.get(`https://node.kutiza.com/krankenkasse/actualModel/${SelectedInsurance.Versicherer}/${plz[0]}/${plz[1]}`).then((response) => {
            setModels(response.data)
            const array_model = [];

            for (let i = 0; i < response.data.length; i++) {
                if (!array_model.includes(response.data[i].Tariftyp)) {
                    array_model.push(response.data[i].Tariftyp)
                }
            }
            setAvailableModels(array_model)
        }).then(() => {
            setIsLoading(false)
        })
    }



    const [reloadKey, setReloadKey] = useState(0);

    const clearAllPersonData = () => {
        setOpen(false)
        setStep(1)
        setPersonData({
            gender: "",
            jahrgang: "",
            plz: "",
        });

        setInsuranceData({})
        setInsuranceAndPrice('')
        setInsurances('')
        setPersons([{ id: 1 }])
        setReloadKey(prevKey => prevKey + 1)
    };

    const FormSubmitValidate = (ev) => {
        ev.preventDefault();
        setTriedSubmit(false)

        if (Step === 2) {
            GetModel()
        }

        if (Step === 10) {
            setIsChatShown(true)

        } else {
            setStep(Step + 1)
        }
    }

    const [TriedSubmit, setTriedSubmit] = useState(false)

    const FormSubmit = (ev) => {
        if (Step === 1) {
            SubmitFirstStepForm(ev)
        } else if (Step === 9) {
            SubmitSevenStepForm(ev)
        } else {
            FormSubmitValidate(ev)
        }
    }

    return (
        <CustomForm onSubmit={FormSubmit} $triedSubmit={TriedSubmit}>
            <div className='stepsContainer'>
                <div className="stepsGreyBorder">
                    <div style={{ display: Step === 1 ? 'block' : 'none' }}>
                        <Step1
                            reloadKey={reloadKey}
                            Expanded={Expanded} setExpanded={setExpanded}
                            PersonData={PersonData} setPersonData={setPersonData}
                            setPersonId={setPersonId} personId={personId}
                            persons={persons} setPersons={setPersons} AddPerson={AddPerson}
                            Step={Step} setStep={setStep}
                        />
                    </div>

                    {
                        Step === 2 && (

                            <Step2
                                setInsuranceAndPrice={setInsuranceAndPrice}
                                InsuranceAndPrice={InsuranceAndPrice}
                                Insurances={Insurances}
                                Step={Step}
                            />
                        )
                    }

                    {
                        (Step === 3 || Step === 4 || Step === 5) && (
                            <Step4
                                InsuranceData={InsuranceData}
                                setInsuranceData={setInsuranceData}
                                insurance={SelectedInsurance}
                                Step={Step}
                                AvailableModels={AvailableModels}
                                Models={Models}
                                IsLoading={IsLoading}
                            />
                        )
                    }
                    {
                        Step === 6 && (
                            <Step3
                                InsuranceData={InsuranceData}
                                insurance={SelectedInsurance}
                            />
                        )
                    }
                    {
                        Step === 7 && (
                            <Step6
                                insurance={SelectedInsurance}
                                setPersonData={setPersonData} PersonData={PersonData}
                                InsuranceData={InsuranceData}
                            />
                        )
                    }
                    {
                        Step >= 8 && (
                            <div style={{ display: Step === 8 ? 'block' : 'none' }}>

                                <Step6Files
                                    insurance={SelectedInsurance}
                                    setPersonData={setPersonData}
                                    PersonData={PersonData}
                                />
                            </div>
                        )
                    }
                    {
                        Step === 9 && (

                            <Step7Signature
                                insurance={SelectedInsurance}
                                setPersonData={setPersonData}
                                PersonData={PersonData}
                            />
                        )
                    }
                    {
                        Step === 10 && (
                            <Step8ThankU />
                        )
                    }

                    {TriedSubmit && (
                        <ErrorBoxWrapper>
                            <ErrorBox />
                        </ErrorBoxWrapper>
                    )}

                </div>

                <Box pb={4}>
                    <Grid container spacing={{ xs: 1, sm: 2 }} justifyContent={'center'}>
                        {
                            Step !== 1 && Step !== 10 && (
                                <Grid item xs={6}>
                                    <PrimaryButton
                                        type={`button`}
                                        onClick={() => { setStep(Step - 1); }}
                                        secondary={true}
                                        disabled={IsLoading}
                                    >
                                        <Grid container columnSpacing={1} justifyContent={'center'} alignItems={'center'}>
                                            <Grid item >
                                                <BackArrow />
                                            </Grid>
                                            <Grid item >
                                                Zurück
                                            </Grid>
                                        </Grid>
                                    </PrimaryButton>
                                </Grid>
                            )

                        }


                        {
                            Step === 1 && (
                                <Grid item xs={6}>
                                    <PrimaryButton
                                        onClick={AddPerson}
                                        type={`button`}
                                        secondary={true}
                                        disabled={IsLoading}
                                    >
                                        <Grid container columnSpacing={1} justifyContent={'center'} alignItems={'center'}>
                                            <Grid item xs={'auto'}>
                                                <AddIcon />
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                {
                                                    width > 700.98
                                                        ? ("Persone hinzufügen")
                                                        : ("Persone")
                                                }
                                            </Grid>
                                        </Grid>
                                    </PrimaryButton>
                                </Grid>
                            )
                        }
                        <Grid item xs={6}>
                            <PrimaryButton
                                type={'submit'}
                                onSubmit={FormSubmit}
                                onClick={() => { setTriedSubmit(true) }}
                                disabled={IsLoading}
                            >
                                <Grid container columnSpacing={1} justifyContent={'center'} alignItems={'center'}>
                                    <Grid item>
                                        Weiter
                                    </Grid>
                                    <Grid item>
                                        <ContinueIcon />
                                    </Grid>
                                </Grid>
                            </PrimaryButton>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} pt={3}>
                        <Grid item>
                            <Button type="button" onClick={handleOpen}>
                                <Grid container columnSpacing={1} alignItems={'center'}>
                                    <Grid item>
                                        <Reset />
                                    </Grid>
                                    <Grid item>
                                        Neustart
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item>
                            <StartOverModal open={open} handleClose={handleClose} cleardata={clearAllPersonData} />
                        </Grid>
                    </Grid>

                </Box>


            </div>
        </CustomForm>
    )
}

export default Steps;
