import React from 'react'
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../hooks/getFunctionDimensions';
const Title = styled.p`
    font-weight:600;
    font-size:16px;
    color:#717680;
    line-height:20px;

    @media (max-width: 899.98px) {
            font-size:14px;
    }
    @media (max-width: 599.98px) { 
        font-size:14px;
    }
`;

const FooterLink = styled.div`
    a{
        text-decoration:none;
        color:#717680 !important;
        font-size:16px;
        line-height:20px;
    }

    @media (max-width: 899.98px) {
        a{
            font-size:10px;
        }
    }

    @media (max-width: 599.98px) { 
        a{
            font-size:10px;
        }
    }
`;

const Links = ({ title, link1, link2, link3, link4 }) => {

    
    const { width } = useWindowDimensions();
    return (
        <>
            <Grid container flexDirection={'column'} paddingTop={width > 699.99 ? "0" :"30px"}>
                <Grid item>
                    <Title>{title}</Title>
                </Grid>
                <Grid item>
                    <Grid container flexDirection={'column'} rowSpacing={1}>
                        <Grid item>
                            <FooterLink><Link>{link1}</Link></FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink><Link>{link2}</Link></FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink><Link>{link3}</Link></FooterLink>
                        </Grid>
                        <Grid item>
                            <FooterLink><Link>{link4}</Link></FooterLink>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default Links;