import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border-radius: 45px;
    padding:12px 10px;
    border: 1px solid #49C0B2;
    background-color:  ${props => props.$secondary ? '#fff' : '#49C0B2'};
    color: ${props => props.$secondary ? '#49C0B2' : '#fff'};
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    max-width:400px;
    
    &:disabled{
        opacity:0.5;
    }
    @media (max-width: 599.98px) { 
        padding: 15px 20px;
    }
`

function PrimaryButton({ children, type, onClick, secondary, onSubmit, style, disabled }) {
    return (
        <Button type={type} onSubmit={onSubmit} onClick={onClick} $secondary={secondary} disabled={disabled}  style={{ ...style }}>
            {children}
        </Button>
    )
}

export default PrimaryButton