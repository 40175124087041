import React from 'react'
import { Grid } from '@mui/material';
import { ReactComponent as Thanku } from '../../../assets/images/chat/thanku.svg';
import styled from 'styled-components';

const Title = styled.p`
    font-size:24px;
    font-weight:700;
    text-align:center;
    line-height:37.2px;

    @media (max-width: 899.98px) {
        font-size:20px;
      
    }

    @media (max-width: 599.98px) {
        font-size:18px; 
    }
`

const Desc = styled.p`
    font-size:18px;
    font-weight:200;
    text-align:center;
    line-height:27.9px;

    @media (max-width: 899.98px) {
        font-size:16px;
      
    }

    @media (max-width: 599.98px) {
        font-size:14px; 
    }
`

const Step8ThankU = () => {
    return (
        <>
            <Grid container justifyContent={'center'} rowSpacing={5}>
                <Grid item>
                    <Thanku style={{ width: "100%", height: "auto" }} />
                </Grid>
                <Grid item>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <Title>Herzlichen Glückwunsch!</Title>
                        </Grid>
                        <Grid item>
                            <Desc>Ihre Wahl über Insulana markiert einen Schritt hin zu persönlicher Krankenversicherungsbetreuung.
                                 Wir haben eine Bestätigung an Ihre E-Mail gesendet, die Ihr ausgewähltes Angebot und die nächsten Schritte detailliert beschreibt. 
                                Sollten Fragen auftauchen, denken Sie daran, dass wir hier sind, um Sie auf jedem Schritt des Weges zu unterstützen.</Desc>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Step8ThankU;
