import React, { useEffect, useRef } from 'react'
import CustomContainer from '../../components/Container';
import { Grid } from '@mui/material';
import gsap from 'gsap';
import { ReactComponent as Vergleichen } from '../../assets/images/home/vergleichen.svg'
import SectionBreaker from '../../components/SectionBreaker';
import styled from 'styled-components';
import { ReactComponent as Pen } from '../../assets/images/home/angeboteclicked.svg';
import { ReactComponent as StyleSite } from '../../assets/images/home/angeboteunclicked.svg';
import { ReactComponent as CreateAnimations } from '../../assets/images/home/vergleichenclicked.svg';
import { ReactComponent as CreateContent } from '../../assets/images/home/vergleichenunclicked.svg';
import { ReactComponent as GoLive } from '../../assets/images/home/aisupportclicked.svg';
import { ReactComponent as Bullet } from '../../assets/images/home/bullet.svg';
// import useWindowDimensions from '../../hooks/getFunctionDimensions';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useWindowDimensions from '../../hooks/getFunctionDimensions';

const SubTitle = styled.div`
    font-size:40px;
    font-weight:700;
    line-height:55px;
    color:#FFF;
    width:100%;
    max-width:400px;
`

const Desc = styled.div`
    font-size:16px;
    font-weight:400;
    line-height:30px;
    color:#FFF;
    width:100%;
    max-width:450px;
    text-align:left;

    @media (max-width: 1440px) { 
      font-size: 14px;
    }
  
`

const Button = styled.button`
  background-color:#FFF;
  color:#49C0B3;
  padding:5px 35px;
  border:none;
  border-radius:64px;
  font-weight:700;
  font-size:18px;
  line-height:40px;

  @media (max-width: 899.98px) { 
    width:100%;  
    padding:8px 50px;
  }

  @media (max-width: 599.98px) { 
    width:100%;   
    padding:10px 60px;
  }
`

const Showmoretitle = styled.div`
  font-size:14px;
  font-weight:700;
  line-height:20px;
  text-align:left;
  width:100%;
  max-width:170px;
`

const Showmoredesc = styled.div`
  font-size:12px;
  font-weight:300;
  line-height:25px;
  text-align:left;
  width:100%;
  max-width:200px;
`

const SectionTwo = () => {

  // const { width } = useWindowDimensions();
  const lineRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);


  function setOpacityForElements(className, opacityValue) {
    const elements = document.getElementsByClassName(className);
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.opacity = opacityValue;
      }
    }
  }

  const toggleActiveElement = (className, heightValue) => {
    const elements = document.getElementsByClassName(className);
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.transition = 'height 1s ease';
        elements[i].style.height = heightValue;
      }
    }
  };

  function resetToggleAllElements() {
    const allElements = document.querySelectorAll('.penresactive, .stylesiteresactive, .createanimationsresactive, .createcontentresactive, .goliveresactive');
    allElements.forEach(element => {
      element.style.height = '0px';
    });
  }

  function resetOpacityForAllElements() {
    const allElements = document.querySelectorAll('.pen, .stylesite, .createanimations, .createcontent, .golive, .penmac, .stylesitemac, .createanimationsmac, .createcontentmac, .golivemac');
    allElements.forEach(element => {
      element.style.opacity = '0.5';
    });
  }

  function resetOpacityForAllResElements() {
    const allElements = document.querySelectorAll('.penres, .stylesiteres, .createanimationsres, .createcontentres, .goliveres');
    allElements.forEach(element => {
      element.style.opacity = '0.5';
    });
  }


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({ repeat: -1 });
    const tb = gsap.timeline({ repeat: -1 });
    const mac = gsap.timeline({ repeat: -1 });

    tl.to(lineRef.current, {
      duration: 20,
      width: '100%',
      ease: 'slow(0.7, 0.7, false)',
      onUpdate: () => {
        const lineWidth = lineRef.current.clientWidth;
        console.log("Line width:", lineWidth);
        if (lineWidth >= 0 && lineWidth < 200) {
          setOpacityForElements('pen', 1);
        } else if (lineWidth >= 300 && lineWidth < 500) {
          setOpacityForElements('stylesite', 1);
        } else if (lineWidth >= 600 && lineWidth < 800) {
          setOpacityForElements('createanimations', 1);
        } else if (lineWidth >= 950 && lineWidth < 1100) {
          setOpacityForElements('createcontent', 1);
        } else if (lineWidth >= 1250) {
          setOpacityForElements('golive', 1);
        } else {
          resetOpacityForAllElements();
        }
      },
    });

    mac.to(lineRef.current, {
      duration: 20,
      width: '100%',
      ease: 'slow(0.7, 0.7, false)',
      onUpdate: () => {
        const lineWidth = lineRef.current.clientWidth;
        console.log("Line width:", lineWidth);
        if (lineWidth >= 0 && lineWidth < 250) {
          resetOpacityForAllElements();
          setOpacityForElements('penmac', 1);
        } else if (lineWidth >= 250 && lineWidth < 500) {
          setOpacityForElements('stylesitemac', 1);
        } else if (lineWidth >= 500 && lineWidth < 800) {
          setOpacityForElements('createanimationsmac', 1);
        } else if (lineWidth >= 800 && lineWidth < 1050) {
          setOpacityForElements('createcontentmac', 1);
        } else if (lineWidth >= 1050) {
          setOpacityForElements('golivemac', 1);
        } else {
          resetOpacityForAllElements();
        }
      },
    });

    tb.to(lineRef.current, {
      duration: 20,
      height: '100%',
      ease: 'slow(0.7, 0.7, false)',
      onUpdate: () => {
        const lineHeight = lineRef.current.clientHeight;
        console.log("Line height:", lineHeight);
        if (lineHeight >= 0 && lineHeight < 300) {
          resetOpacityForAllResElements();
          resetToggleAllElements();
          setOpacityForElements('penres', 1);
          toggleActiveElement('penresactive', '100%');
        } else if (lineHeight >= 300 && lineHeight < 600) {
          setOpacityForElements('stylesiteres', 1);
          toggleActiveElement('stylesiteresactive', '100%');
        } else if (lineHeight >= 600 && lineHeight < 900) {
          setOpacityForElements('createanimationsres', 1);
          toggleActiveElement('createanimationsresactive', '100%');
        } else if (lineHeight >= 900 && lineHeight < 1200) {
          setOpacityForElements('createcontentres', 1);
          toggleActiveElement('createcontentresactive', '100%');
        } else if (lineHeight >= 1100) {
          setOpacityForElements('goliveres', 1);
          toggleActiveElement('goliveresactive', '100%');
        } else {
          resetOpacityForAllElements();
          resetToggleAllElements();
        }
      },
    });

    return () => {
      tl.kill();
      tb.kill();
      mac.kill();
    };
  }, []);

  const { width } = useWindowDimensions();

  return (
    <SectionBreaker>
      <Grid container backgroundColor={"#49C0B3"} position={"relative"}>
        <CustomContainer>
          <Grid container flexDirection={"column"}  >
            <Grid item>
              {width > 1450 ? (
                <Grid container pl={13} pt={15} pb={width < 1450 ? 20 : 40} justifyContent={'center'} rowSpacing={0} columnSpacing={12}>
                  <div style={{ paddingBottom: "20px", display: "flex", flexDirection: "row" }}>
                    <div className='pen' style={{ paddingRight: "140px" }}>
                      <Pen />
                    </div>
                    <div className='stylesite' style={{ paddingInline: "140px" }}>
                      <StyleSite />
                    </div>
                    <div className='createanimations' style={{ paddingInline: "140px" }}>
                      <CreateAnimations />
                    </div>
                    <div className='createcontent' style={{ paddingInline: "140px" }}>
                      <CreateContent />
                    </div>
                    <div className='golive' style={{ paddingLeft: "140px" }}>
                      <GoLive />
                    </div>
                  </div>
                  <div style={{ width: '100%', height: '2px', backgroundColor: '#A4DFD9', borderRadius: "10px" }}>
                    <div
                      ref={lineRef}
                      style={{
                        width: '0%',
                        height: '100%',
                        backgroundColor: 'white',
                        transformOrigin: 'left center',
                        position: "relative",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <div style={{ position: "absolute", top: -11, right: 0 }}>
                        <Bullet />
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", fontSize: "16px", lineHeight: "16px", fontWeight: "700" }}>
                    <div className='pen' style={{ paddingRight: "160px", color: "#fff" }}>
                      Structure your page
                    </div>
                    <div className='stylesite' style={{ paddingRight: "205px", color: "#fff" }}>
                      Style your site
                    </div>
                    <div className='createanimations' style={{ paddingRight: "175px", color: "#fff" }}>
                      Create animations
                    </div>
                    <div className='createcontent' style={{ paddingRight: "180px", color: "#fff" }}>
                      Create content
                    </div>
                    <div className='golive' style={{ color: "#fff" }}>
                      Go live
                    </div>
                  </div>
                </Grid>
              ) : width < 1450 && width > 899.99 ? (
                <Grid container pl={6} pt={15} pb={width < 1450 ? 20 : 40} justifyContent={'center'} rowSpacing={0} columnSpacing={12}>
                  <div style={{ paddingBottom: "20px", display: "flex", flexDirection: "row" }}>
                    <div className='penmac' style={{ paddingRight: "115px" }}>
                      <Pen />
                    </div>
                    <div className='stylesitemac' style={{ paddingInline: "115px" }}>
                      <StyleSite />
                    </div>
                    <div className='createanimationsmac' style={{ paddingInline: "115px" }}>
                      <CreateAnimations />
                    </div>
                    <div className='createcontentmac' style={{ paddingInline: "115px" }}>
                      <CreateContent />
                    </div>
                    <div className='golivemac' style={{ paddingLeft: "115px" }}>
                      <GoLive />
                    </div>
                  </div>
                  <div style={{ width: '100%', height: '2px', backgroundColor: '#A4DFD9', borderRadius: "10px" }}>
                    <div
                      ref={lineRef}
                      style={{
                        width: '0%',
                        height: '100%',
                        backgroundColor: 'white',
                        transformOrigin: 'left center',
                        position: "relative",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <div style={{ position: "absolute", top: -11, right: 0 }}>
                        <Bullet />
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", fontSize: "16px", lineHeight: "16px", fontWeight: "700" }}>
                    <div className='penmac' style={{ paddingRight: "100px", color: "#fff" }}>
                      Structure your page
                    </div>
                    <div className='stylesitemac' style={{ paddingRight: "150px", color: "#fff" }}>
                      Style your site
                    </div>
                    <div className='createanimationsmac' style={{ paddingRight: "140px", color: "#fff" }}>
                      Create animations
                    </div>
                    <div className='createcontentmac' style={{ paddingRight: "130px", color: "#fff" }}>
                      Create content
                    </div>
                    <div className='golivemac' style={{ color: "#fff" }}>
                      Go live
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid container pl={5} pt={8} pb={5} justifyContent={'center'} rowSpacing={2} columnSpacing={12}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ width: '2px', height: '100%', backgroundColor: '#A4DFD9', borderRadius: "10px", marginRight: "20px" }}>
                      <div
                        ref={lineRef}
                        style={{
                          height: '0%',
                          width: '100%',
                          backgroundColor: 'white',
                          transformOrigin: 'top',
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}
                      >
                        <div style={{ position: "absolute", bottom: -7, right: -1.5 }}>
                          <Bullet />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='penres' style={{ opacity: "0.5", display: "flex", flexDirection: "column", paddingBottom: "20px", color: "#fff", fontSize: "14px", fontWeight: "700" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div>
                            <Pen style={{ width: "100%", maxWidth: "27px", height: "auto" }} />
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            Structure your page
                          </div>
                        </div>
                        <div className='penresactive' style={{ display: "flex", flexDirection: "column", paddingTop: "15px", height: "0px", overflow: 'hidden' }}>
                          <Showmoretitle>
                            Fully customisable page
                            structure
                          </Showmoretitle>
                          <Showmoredesc>
                            Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.
                          </Showmoredesc>
                        </div>
                      </div>
                      <div className='stylesiteres' style={{ opacity: "0.5", display: "flex", paddingTop: "30px", flexDirection: "column", paddingBottom: "20px", color: "#fff", fontSize: "14px", fontWeight: "700" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div>
                            <StyleSite style={{ width: "100%", maxWidth: "27px", height: "auto" }} />
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            Style your site
                          </div>
                        </div>
                        <div className='stylesiteresactive' style={{ display: "flex", flexDirection: "column", paddingTop: "15px", height: "0px", overflow: 'hidden' }}>
                          <Showmoretitle>
                            Fully customisable page
                            structure
                          </Showmoretitle>
                          <Showmoredesc>
                            Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.
                          </Showmoredesc>
                        </div>
                      </div>
                      <div className='createanimationsres' style={{ opacity: "0.5", paddingTop: "30px", display: "flex", flexDirection: "column", paddingBottom: "20px", color: "#fff", fontSize: "14px", fontWeight: "700" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div>
                            <CreateAnimations style={{ width: "100%", maxWidth: "27px", height: "auto" }} />
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            Create animations
                          </div>
                        </div>
                        <div className='createanimationsresactive' style={{ display: "flex", flexDirection: "column", paddingTop: "15px", height: "0px", overflow: 'hidden' }}>
                          <Showmoretitle>
                            Fully customisable page
                            structure
                          </Showmoretitle>
                          <Showmoredesc>
                            Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.
                          </Showmoredesc>
                        </div>
                      </div>
                      <div className='createcontentres' style={{ opacity: "0.5", paddingTop: "30px", display: "flex", flexDirection: "column", paddingBottom: "20px", color: "#fff", fontSize: "14px", fontWeight: "700" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div>
                            <CreateContent style={{ width: "100%", maxWidth: "27px", height: "auto" }} />
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            Create content
                          </div>
                        </div>
                        <div className='createcontentresactive' style={{ display: "flex", flexDirection: "column", paddingTop: "15px", height: "0px", overflow: 'hidden' }}>
                          <Showmoretitle>
                            Fully customisable page
                            structure
                          </Showmoretitle>
                          <Showmoredesc>
                            Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.
                          </Showmoredesc>
                        </div>
                      </div>
                      <div className='goliveres' style={{ opacity: "0.5", paddingTop: "30px", display: "flex", flexDirection: "column", color: "#fff", fontSize: "14px", fontWeight: "700" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div>
                            <GoLive style={{ width: "100%", maxWidth: "27px", height: "auto" }} />
                          </div>
                          <div style={{ paddingLeft: "5px" }}>
                            Go live
                          </div>
                        </div>
                        <div className='goliveresactive' style={{ display: "flex", flexDirection: "column", paddingTop: "15px", height: "0px", overflow: 'hidden' }}>
                          <Showmoretitle>
                            Fully customisable page
                            structure
                          </Showmoretitle>
                          <Showmoredesc>
                            Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.
                          </Showmoredesc>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              )
              }
            </Grid>
            <Grid item xs={4}>
              <Grid container flexDirection={"column"} rowSpacing={3} pb={width > 899 ? 20 : 6}>
                {width > 899.99 && (
                  <>
                    <Grid item>
                      <SubTitle>Fully customisable page structure</SubTitle>
                    </Grid>
                    <Grid item>
                      <Desc>Build an incredible workplace and grow your business with Gusto’s all-in-one platform with amazing contents. Get your tests delivered at let home collect sample from the victory of the supplies design system.</Desc>
                    </Grid>
                  </>
                )}

                <Grid item>
                  <Button>Go to chat</Button>
                </Grid>
              </Grid>
            </Grid>
            {
              width > 899.99 && (
                <>
                  <Grid item xs={6}>
                    <Grid container justifyContent={"end"} position={"absolute"} bottom={width < 1450 ? -1 : -1} right={0}>
                      <Vergleichen style={{ maxWidth: width < 1450 ? "730px" : '950px', width: '70%',height: 'auto' }} /> 
                    </Grid>
                  </Grid>
                </>
              )
            }
          </Grid>
        </CustomContainer>
      </Grid>
    </SectionBreaker>

  )
}

export default SectionTwo