import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

const Container = styled.div`
  max-width:1350px;

  @media (max-width: 1440px) { 
    max-width:1020px;

  }
`

const CustomContainer = ({ children, style }) => {
  return (
    <Container className="container-lg px-4" style={{...style}}>
      {children}
    </Container>
  );
};

export default CustomContainer;
