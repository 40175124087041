import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import styled from 'styled-components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "708px",
    backgroundColor: "#FFFFFF",
    color: '#000',
    p: 4,
    borderRadius: 1.5
};

const BoxWrapper = styled.div`
    .MuiBox-root {
        padding:30px;
    }

    @media (max-width: 899.98px) {
        .MuiBox-root {
           max-width:390px;
           height:400px;
        }
    }

    @media (max-width: 599.98px) { 
        .MuiBox-root {
            max-width:390px;
            height:400px;
        }
    }
`

const ModalBox = styled(Modal)`
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
        backdrop-filter: blur(5px);
    }

    @media (max-width: 899.98px) {
        .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
            backdrop-filter: blur(5px);
        }
    }

    @media (max-width: 599.98px) { 
        .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
            backdrop-filter: blur(5px);
        }
    }
`

const Title = styled.span`
    display:block;
    font-size:15.45px;
    font-weight:700;
    line-height:23.17px;
    color:#49BFB3;

    @media (max-width: 899.98px) {
        font-size:15px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
    }
`

const Desc = styled.span`
    display:block;
    font-size:11.59px;
    font-weight:700;
    line-height:17.38px;   
    color:#132F41;

    @media (max-width: 899.98px) {
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
    }
`

const ScrollWrapper = styled.div`
    height:200px;
    font-size:10.94px;
    overflow-y:auto;
    font-weight:500;
    line-height:16.41px;
    padding-right:15px;

    /* WebKit Scrollbar */
    &::-webkit-scrollbar {
        width: 7.8px;
    }

    &::-webkit-scrollbar-track {
        background: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        background: #49BFB3;
        border-radius:64.36px;
    }
`

const AgreeButton = styled.button`
    width:100%;
    background-color:#49BFB3;
    color:#FFFFFF;
    padding:7px 45px;
    border-radius:4.67px;
    border:none;
    font-size:12px;
    font-weight:600;
    line-height:15.45px;
`

const CancelButton = styled.button`
    width:100%;
    background-color:transparent;
    color:#49BFB3;
    padding:7px 45px;
    border-radius:4.67px;
    border:none;
    font-size:12px;
    font-weight:600;
    line-height:15.45px;
`

export default function TermsModal({
    open,
    handleClose,
    Agree
}) {
    return (
        <div>
            <ModalBox
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <BoxWrapper>
                    <Box sx={style}>
                        <Grid container rowSpacing={3} flexDirection={'column'} >
                            <Grid item>
                                <Title>
                                    Terms and Conditions
                                </Title>
                            </Grid>
                            <Grid item>
                                <Desc>
                                    Allgemeine Geschäftsbedingungen
                                </Desc>
                            </Grid>
                            <Grid item>
                                <ScrollWrapper>


                                    Ihre Zustimmung<br />
                                    Willkommen bei Insulana. Durch den Zugriff auf unsere Plattform stimmen Sie den folgenden Bedingungen zu:<br />
                                    Nutzung des Dienstes: Insulana ermöglicht Benutzern den Vergleich von Krankenversicherungsoptionen in der Schweiz. Die Dienste stehen Einwohnern der Schweiz über 18 Jahren zur Verfügung.<br />
                                    Datenschutz: Wir verpflichten uns, Ihre persönlichen Daten zu schützen. Einzelheiten zur Datenverarbeitung finden Sie in unserer Datenschutzerklärung.<br />
                                    Benutzerverantwortung: Sie müssen genaue Informationen für die effektive Nutzung des Dienstes bereitstellen. Missbrauch oder betrügerische Aktivitäten sind verboten.<br />
                                    Urheberrecht: Inhalte auf Insulana sind unser Eigentum oder das Eigentum unserer Lizenzgeber und sind durch Urheberrechtsgesetze geschützt.
                                    Haftungsbeschränkungen: Insulana ist nicht verantwortlich für Entscheidungen, die auf Informationen unserer Plattform basieren. Wir bemühen uns um Genauigkeit, können jedoch die Abwesenheit von Fehlern nicht garantieren.<br />
                                    Änderungen: Wir können diese Bedingungen jederzeit aktualisieren. Die fortgesetzte Nutzung des Dienstes bedeutet die Akzeptanz neuer Bedingungen.<br />
                                    Anwendbares Recht: Diese Bedingungen unterliegen dem Schweizer Recht.
                                </ScrollWrapper>
                            </Grid>
                            <Grid item>
                                <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                                    <Grid item xs={"auto"}>
                                        <CancelButton type='button' onClick={handleClose}>Abbrechen</CancelButton>
                                    </Grid>
                                    <Grid item xs={"auto"}>
                                        <AgreeButton type='button' onClick={Agree}>Zustimmen</AgreeButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </BoxWrapper>
            </ModalBox>
        </div>
    );
}