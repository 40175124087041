import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components';
import CustomContainer from './Container';
// import Twitter from '../assets/images/home/twitter.svg'
// import Facebook from '../assets/images/home/facebook.svg'
// import Linkedin from '../assets/images/home/linkedin.svg'
import Logo from '../assets/images/home/logo.svg'
import Links from '../components/Links';
import SectionBreaker from './SectionBreaker';
import useWindowDimensions from '../hooks/getFunctionDimensions';

const Title = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
  text-align:center;
  max-width: 800px;
  margin:auto;

  @media (max-width: 1440px) { 
    font-size: 32px;
    max-width: 600px;
    }

  @media (max-width: 1199.98px) { 
    font-size: 40;
    line-height: 45px;
  }

  @media (max-width: 899.98px) { 

    font-size: 38px;
    line-height: 35px;
  }

  @media (max-width: 599.98px) { 
    font-size: 16px;
    line-height: 30px;
  }
`;

const Desc = styled.p`
  width:75%;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  text-align:center;
  margin:auto;
  padding-top: 30px;
  width:100%;
  max-width:600px;

  @media (max-width: 899.98px) { 
    font-size: 10px;
    line-height: 16px;
  }

  @media (max-width: 599.98px) { 
    font-size: 10px;
    line-height: 16px;
  }
`;

const StyledInput = styled.input`
  width:100%;
  font-size: 14px;
  outline: none;
  border: none;
  background-color:#59C5C0; 
  color:#FFFFFF;
  border-radius:47px;
  padding:15px 15px;

  &::placeholder{
    color:#FFFFFF;
  }

  @media (max-width: 899.98px) { 
    font-size: 10px;
    padding:10px 10px;
  }

  @media (max-width: 599.98px) { 
    font-size: 10px;
    padding:10px 10px;
  }
`;

const StyledButton = styled.button`
  width:100%;
  font-size: 14px;
  outline: none; 
  border: none; 
  background-color: #f0f0f0; 
  border-radius:47px;
  padding:15px 15px;
  color:#47BEB9;
  font-weight:600;
  margin-left:0px;

  @media (max-width: 899.98px) { 
    font-size: 10px;
    padding:10px 10px;
  }

  @media (max-width: 599.98px) { 
    font-size: 10px;
    padding:10px 10px;
  }
`;


const Wrapper = styled(Grid)`
    padding:100px 10px;

    @media (max-width: 1440px) { 
        padding:30px 10px;
    }
`
const Text = styled.p`
    font-weight:400;
    font-size:14px;
    padding-top:10px;
    width:100%;
    max-width:300px;

    @media (max-width: 1440px) { 
        font-size:14px;
    }

    @media (max-width: 1199.98px) { 
        font-size: 14px;
    }
    
    @media (max-width: 899.98px) {  
        font-size: 13px;
    }
    
    @media (max-width: 599.98px) { 
        font-size: 13px;
    }
`;

const Rights = styled.p`
    font-weight:400;
    font-size:16px;
    width:100%;
    margin-top:10px;
    color:#717680;
    text-align:center;
    width:100%;
    max-width:1100px;
    @media (max-width: 599.98px) { 
        font-size: 10px;
    }
`;

const Footer = () => {
    const { width } = useWindowDimensions();
    return (
        <>
            <SectionBreaker>
                <Grid container justifyContent={'center'} flexDirection={'column'}>
                    <Wrapper item style={{ backgroundColor: "#47BEB9", justifyContent: 'center' }} >
                        <CustomContainer>
                            <Grid item>
                                <Title>Bereit, Ihre Krankenversicherung zu optimieren?</Title>
                            </Grid>
                            <Grid item>
                                <Desc>Treten Sie den Tausenden bei, die bereits mit Insulana eine bessere Versicherung gefunden haben</Desc>
                            </Grid>
                            <Grid item  >
                                <Grid container justifyContent={'center'} columnSpacing={2} rowSpacing={2} paddingTop={'30px'}>
                                    <Grid item xs={7} md={6} sm={6} lg={4}>
                                        <StyledInput type="text" placeholder='Geben sie ihre E-Mailadresse ein'></StyledInput>
                                    </Grid>
                                    <Grid item xs={5} md={4} sm={4} lg={3}>
                                        <StyledButton type='submit'>Jetzt vergleichen</StyledButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </CustomContainer>
                    </Wrapper>
                    <CustomContainer>
                        <Grid container spacing={4} justifyContent={'center'} flexDirection={'row'} padding={'48px 0'} >
                            <Grid item xs={12} lg={3} xl={4}>
                                <Grid container flexDirection={'column'} rowSpacing={2}>
                                    <Grid item>
                                        <img src={Logo} alt='logo' />
                                    </Grid>
                                    {width > 599 && (

                                        <Grid item>
                                            <Text>Bereit, Ihre Krankenversicherung zu optimieren?</Text>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item flexDirection={'row'} justifyContent={'flex-end'} xs >
                                <Grid container justifyContent={'flex-end'} columnSpacing={5} >
                                    <Grid item xs={4} lg={3} xl={3}>
                                        <Links
                                            title={'SOCIALS'}
                                            link1={'Instagram'}
                                            link2={'Facebook'}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3} xl={3}>
                                        <Links
                                            title={'RECHTLICHES'}
                                            link1={'Impressum'}
                                            link2={'Datenschutz'}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3} xl={3}>
                                        <Links
                                            title={'INSULANA'}
                                            link1={'Über uns'}
                                            link2={'Projekte'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={'center'} margin={'10px 0'}>
                            <Grid item >
                                <Rights>© 2024 Insulana GmbH. Alle Rechte vorbehalten. Inhalte auf dieser Website sind durch Urheberrechtsgesetze geschützt und Eigentum von Insulana. Eine unerlaubte Nutzung ist untersagt.</Rights>
                            </Grid>
                        </Grid>
                    </CustomContainer>
                </Grid>
            </SectionBreaker>
        </>
    )
}

export default Footer