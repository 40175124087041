import React, { useState } from 'react';
import styled from 'styled-components'
import { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as UploadIcon } from '../assets/images/chat/uploadIcon.svg'
import { ReactComponent as X } from '../assets/images/chat/fileCloseBtn.svg'

const InputContainer = styled.div`
    input::placeholder {
        color: #DADEE4;
        font-size: 14px;
        font-weight: 500;
    }

    input:valid {
        border: 1px solid #DADEE4;
    }
    
    input:focus:invalid {
        border: 1px solid #F43F5E;
    }
`

const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`

const SmallSpan = styled.div`
    color: #64748B;
    font-size: 9px;
    line-height: 13.5px;
    padding-top: 5px;
    opacity: 50%;
`

const FileInputWrapper = styled.label`
    position: relative;
    border: 1px dashed #CFD6DD;
    border-radius: 1px;
    width: 100%;
    background-color: #F6F9F9;
    padding: 15px;
    font-size: 14px;
    aspect-ratio: 16/4;
    cursor: pointer;
    display: grid;
    place-items: center;
    text-align: center;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    input {
        width: 100%;
        opacity: 0;
        position: absolute;
        height: 100%;
    }

    span {
        font-size: 14px;
        color: #49BFB3;
    }

`

const AfterUploadBox = styled.div`
    background-color: #fff;
    border: 1px solid #CFD6DD;
    border-radius: 5px;
    padding: 1rem;
`

const FileSizeStyle = styled.div`
    font-size: 14px;
    color: #979797;
`

const FileNameStyle = styled.div`
    color: #333333;
    font-size: 14px;
    padding-bottom: 5px;
`

const FileUploadInput = forwardRef(function ({ label, name, required, svg, setPersonData }, ref) {

    const [FileName, setFileName] = useState('')
    const [FileSize, setFileSize] = useState('')

    const handleInputChange = (e) => {
        const { name, files } = e.target;
        setPersonData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const FileInput = (e) => {


        if (e.target.files[0]) {
            let totalBytes = e.target.files[0].size;
            let size;
            if (totalBytes < 1000000) {
                size = Math.floor(totalBytes / 1000) + 'KB';

            } else {
                size = Math.floor(totalBytes / 1000000) + 'MB';
            }

            setFileName(e.target.files[0].name)
            setFileSize(size)
            handleInputChange(e)
        }

        else {
            setFileName('')
        }
    }

    return (
        <InputContainer>
            {
                (label) && (
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <LabelStyle>
                                {label}
                            </LabelStyle>
                        </Grid>
                    </Grid>

                )
            }
            {
                FileName === '' ? (
                    <Grid container columnSpacing={2} alignItems={'center'}>
                        <Grid item xs>
                            <FileInputWrapper htmlFor={name} onDrop={(e) => {FileInput(e)}}>
                                <input accept='application/pdf, image/*' required={required} type="file" name={name} id={name} onChange={FileInput} onDropCapture={FileInput} onDrop={(e) => {FileInput(e)}}  />
                                <Grid container alignItems={'center'} flexDirection={'column'} justifyContent={'center'} rowSpacing={0}>
                                    <Grid item xs={'auto'}>
                                        <UploadIcon />
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <div>
                                            Drag & Drop oder <span>Datei auswählen</span> zum Hochladen
                                        </div>
                                    </Grid>
                                </Grid>
                            </FileInputWrapper>
                            <SmallSpan>
                                Unterstützte Formate: png, jpg, pdf
                            </SmallSpan>
                        </Grid>
                    </Grid>
                )
                    : (
                        <AfterUploadBox>
                            <Grid container alignItems={'center'}>
                                <Grid item xs>
                                    <FileNameStyle>
                                        {FileName}

                                    </FileNameStyle>
                                    <FileSizeStyle>
                                        {FileSize}

                                    </FileSizeStyle>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {setFileName('')}}>
                                        <X />
                                    </div>
                                </Grid>
                            </Grid>
                        </AfterUploadBox>
                    )
            }

        </InputContainer >
    )
})

export default FileUploadInput