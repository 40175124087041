import React from 'react'
import CustomContainer from '../../components/Container';
import { Grid } from '@mui/material';
import { ReactComponent as Insulanasvg } from '../../assets/images/home/insulana.svg'
import { ReactComponent as DownIcon } from '../../assets/images/home/downIcon.svg';
// import csslogo from '../../assets/images/home/csslogo.svg'
import styled from 'styled-components';
import SectionBreaker from '../../components/SectionBreaker';
import useWindowDimensions from '../../hooks/getFunctionDimensions';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';


const Title = styled.div` 
  font-size:55px;
  font-weight:700;
  line-height:80px;
  color:#02073E;
  width:100%;
  max-width:700px;

  @media (max-width: 1440px) { 
    font-size: 40px;
    line-height: 54px;
    max-width:800px;
  }

  @media (max-width: 1199.98px) { 
    font-size: 45px;
    line-height: 54px;
  }

  @media (max-width: 899.98px) { 
    font-size: 35px;
    line-height: 44px;
  }

  @media (max-width: 599.98px) { 
    font-size: 32px;
    line-height: 35px;
  }
`

const GreenWord = styled.span`
  display:block
  font-size:55px;
  font-weight:700;
  line-height:80px;
  color:#49C0B3;

  
  @media (max-width: 1440px) { 
    font-size: 45px;
    line-height: 54px;
  }

  @media (max-width: 1199.98px) { 
    font-size: 45px;
    line-height: 54px;
  }

  @media (max-width: 899.98px) { 
    font-size: 35px;
    line-height: 44px;
  }

  @media (max-width: 599.98px) { 
    font-size: 32px;
    line-height: 35px;
  }
`

const Paragraph = styled.div`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight:400;
    font-size: 18px;
    max-width: 500px;
    line-height:42px;
    text-align: left;

    @media (max-width: 899.98px) { 
      font-size: 20px;
      line-height: 27px;   
    }

    @media (max-width: 599.98px) { 
      font-size: 16px;
      line-height: 25px;   
    }

`

const Button = styled.button`
   border-radius:63px;
   color:white;
   background-color:#49C0B3;
   box-shadow:none;
   font-size:18px;
   font-weight:bold;
   padding:15px 60px;
   border:none;

   @media (max-width: 899.98px) { 
    width:100%;  
    padding:20px 60px;
  }

  @media (max-width: 599.98px) { 
    width:100%;   
    padding:15px 60px;
  }
`

const Section = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <CustomContainer>
        <SectionBreaker>
          <Grid container flexDirection={'row'} columnSpacing={3} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} lg={6}>
              <Grid container flexDirection={'column'} rowSpacing={3}>
                <Grid item>
                  <Title>
                    Lorem ipsum dolor sit amet consectetur. Nunc <GreenWord>insulana</GreenWord> semper.
                  </Title>
                </Grid>
                <Grid item>
                  <Paragraph>
                    Lorem ipsum dolor sit amet consectetur. Senectus pharetra netus pellentesque pulvinar justo. Sagittis condimentum mi ridiculus sed semper. Nulla et vitae.
                  </Paragraph>
                </Grid>
                <Grid item>
                  <Link to={'/chat'}>
                    <Button>
                      Go to chat
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            {
              width > 899.99 ?
                (

                  <Grid item xs={12} lg={6}>
                    <Insulanasvg />
                  </Grid>) : (
                  <Grid item xs={12} lg={6}>
                    <Insulanasvg style={{ width: "100%", maxWidth: "400px", height: "410px" }} />
                  </Grid>
                )
            }
          </Grid>
          {
            width > 1500 ? (
              <Grid container justifyContent={"center"} pt={5}>
                <DownIcon />
              </Grid>
            ) : width < 1500 && width > 899.99 ? (
              <Grid container justifyContent={"center"} pt={5}>
                <DownIcon style={{ width: "100%", height: "auto", maxWidth: "35px" }} />
              </Grid>
            ) : (
             null
            )
          }

        </SectionBreaker>
      </CustomContainer >
    </>
  )
}

export default Section