import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import Chat from "./screens/Chat";


function App() {


  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/chat" element={<Chat />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
