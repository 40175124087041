import React, { useEffect, useRef, useState } from 'react'
import PrimaryInput from './PrimaryInput';
import styled from 'styled-components'

const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`

function PLZInput({ regions, PersonData, setPersonData, inputName, label, defaultValue, index }) {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const [filter, setFilter] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false)

    const inputChange = useRef(null)
    const firstPlz = useRef(null)

    const showSelectDropdown = () => {
        setIsFocused(true)

    }

    const hideSelectDropdown = () => {
        if (PersonData.plz1 === '') {
            inputChange.current.value = ''
        }

        setTimeout(() => {
            setIsFocused(false)
        }, 150);

    }

    const handleInput = (e) => {
        inputChange.current.value = e.target.getAttribute('value');
        setInputValue(e.target.getAttribute('value'))
        setIsFocused(false)

        setPersonData((prevFormData) => ({
            ...prevFormData,
            [inputName]: e.target.getAttribute('data-kanton') + ' ' + e.target.getAttribute('data-region'),
            "addresse": e.target.getAttribute('value')
        })

        );
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13 && firstPlz.current) {

            let value = firstPlz.current.getAttribute('data-plz') + ' ' + firstPlz.current.getAttribute('data-ort')
            inputChange.current.value = value
            setInputValue(value)
            setIsFocused(false)

            setPersonData((prevFormData) => ({
                ...prevFormData,
                [inputName]: firstPlz.current.getAttribute('data-kanton') + ' ' + firstPlz.current.getAttribute('data-region'),
                "addresse": value
            })

            );
        }
    }


    useEffect(() => {
        const searchRegion = (e) => {
            let region;

            if (inputValue.length < 4) {
                if (!isNaN(inputValue)) {
                    region = regions.filter((element) => (element.plz.substr(0, inputValue.length)) === (inputValue))
                }
                else {
                    region = regions.filter((element) => (element.plz + " " + element.ort).includes(inputValue))
                }
            }
            else {
                region = regions.filter((element) => (element.plz + " " + element.ort).includes(inputValue))
            }

            if (inputValue === '' || inputValue === null) {
                setFilter([]);
                setIsEmpty(true)
            }
            else {
                setFilter(region);
                setIsEmpty(false)
            }
        }
        searchRegion()

    }, [inputValue, regions])

    return (
        <>
            <div style={{ position: 'relative' }}>
                {
                    label && (
                        <LabelStyle>
                            {label}
                        </LabelStyle>
                    )
                }
                <PrimaryInput
                    ref={inputChange}
                    required
                    type="text"
                    name={inputName}
                    placeholder='3013 Bern (Bern)'
                    style={{ padding: '10px 15px' }}
                    onChange={(e) => { setInputValue(e.target.value) }}
                    onFocus={showSelectDropdown}
                    onBlur={hideSelectDropdown}
                    autoComplete='off'
                    onKeyDownCapture={handleEnter}
                    hideInvalidMessage={true}
                />

                {(isFocused && !isEmpty) && (
                    <div className='plzDropdown'>

                        {filter.map((element, index) => {
                            return (
                                <div ref={index === 0 ? firstPlz : null} key={index} id={index} className='selectOptionStyle'
                                    onClick={handleInput}
                                    value={element.plz + " " + element.ort}
                                    data-region={element.region}
                                    data-kanton={element.kanton}
                                    data-ort={element.ort}
                                    data-plz={element.plz}
                                    data-commune={element.commune}
                                >
                                    {element.plz + " " + element.ort + (element.ort === element.commune ? '' : (" (" + element.commune + ")"))}
                                </div>
                            )
                        })}

                    </div>
                )}


            </div >
        </>
    )
}

export default PLZInput