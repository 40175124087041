import React, { useState } from 'react';
import styled from 'styled-components'
import { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as WhiteUpload } from '../assets/images/chat/whiteupload.svg'
import { ReactComponent as AusweisFront } from '../assets/images/chat/ausweisfront.svg'
import { ReactComponent as X } from '../assets/images/chat/fileCloseBtn.svg'
import useWindowDimensions from '../hooks/getFunctionDimensions';

const InputContainer = styled.div`
    input::placeholder {
        color: #DADEE4;
        font-size: 14px;
        font-weight: 500;
    }

    input:valid {
        border: 1px solid #DADEE4;
    }
    
    input:focus:invalid {
        border: 1px solid #F43F5E;
    }
`

const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`

const SmallSpan = styled.div`
    color: #64748B;
    font-size: 9px;
    line-height: 13.5px;
    padding-top: 5px;
    opacity: 50%;
`

const FileInputWrapper = styled.label`
    border: 1px dotted #CFD6DD;
    border-radius: 5px;
    width: 100%;
    background-color: #F6F9F9;
    padding: 15px;
    font-size: 14px;
    aspect-ratio: 16/4;
    cursor: pointer;
    display: grid;
    place-items: center;
    text-align: center;
    position: relative;
    input {
        width: 100%;
        opacity: 0;
        position: absolute;
        height: 100%;
    }

`

const AfterUploadBox = styled.div`
    border: 1px dotted #CFD6DD;
    border-radius: 5px;
    width: 100%;
    background-color: #F6F9F9;
    padding:35px;
    font-size: 14px;
    aspect-ratio: 16/4;
    cursor: pointer;
    display: grid;
    place-items: center;
    text-align: center;
    position:relative;
    
`

const FileSizeStyle = styled.div`
    font-size: 14px;
    color: #979797;
`

const FileNameStyle = styled.div`
    color: #333333;
    font-size: 14px;
    padding-bottom: 5px;
`

const Button = styled.div`
    background-color:#49BFB3;
    color: #FBFBFB;
    border:none;
    border-radius:45px;
    padding:6px 25px;
    font-size:13px;

    @media (max-width: 899.98px) {
        font-size:10px;
        padding:5px 13px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
        padding:5px 13px;
    }
`

const Text = styled.span`
    font-size:12px;
    line-height:12px;
    font-weight:400;
    letter-spacing:0.25px;
    color:#333333;
    @media (max-width: 899.98px) {
        font-size:10px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
    }
`

const Greentext = styled.span`
    color:#49BFB3;
    font-size:12px;
    font-weight:400;
    @media (max-width: 899.98px) {
        font-size:10px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
    }
`

const Remove = styled.div`
    position:absolute;
    top:10px;
    right:12px;
`

const FileUploadInputAuswies = forwardRef(function ({ label, name, required, svg, greentext, setPersonData }, ref) {
    const [FileName, setFileName] = useState('')
    const [FileSize, setFileSize] = useState('')

    const { width } = useWindowDimensions();

    const handleInputChange = (e) => {

        const { name, files } = e.target;
        setPersonData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const FileInput = (e) => {


        if (e.target.files[0]) {
            let totalBytes = e.target.files[0].size;
            let size;
            if (totalBytes < 1000000) {
                size = Math.floor(totalBytes / 1000) + 'KB';

            } else {
                size = Math.floor(totalBytes / 1000000) + 'MB';
            }

            setFileName(e.target.files[0].name)
            setFileSize(size)
            handleInputChange(e)
        }

        else {
            setFileName('')
        }
    }

    return (
        <InputContainer>
            {
                (label) && (
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <LabelStyle>
                                {label}
                            </LabelStyle>
                        </Grid>
                    </Grid>

                )
            }
            {
                FileName === '' ? (
                    <Grid container columnSpacing={2} alignItems={'center'}>
                        <Grid item xs>
                            <FileInputWrapper htmlFor={name}>
                                <input accept='application/pdf, image/*' required={required} type="file" id={name} name={name} onChange={FileInput} />
                                <Grid container alignItems={'center'} flexDirection={'column'} justifyContent={'center'} rowSpacing={2}>
                                    <Grid item xs={'auto'}>
                                        {svg}
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <div>
                                            <Text>Laden Sie die <Greentext>{greentext}</Greentext> Ihres Personalausweises hoch</Text>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Button>
                                            <Grid container columnSpacing={0.5} alignItems={'center'}>
                                                <Grid item>
                                                    <WhiteUpload />
                                                </Grid>
                                                <Grid item>
                                                    Datei&nbsp; hochladen
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FileInputWrapper>
                            <SmallSpan>
                                Unterstützte Formate: png, jpg
                            </SmallSpan>
                        </Grid>
                    </Grid>
                )
                    : (
                        <>
                            <AfterUploadBox>
                                <Grid container justifyContent={'center'} alignItems={'center'} rowSpacing={3}>
                                    <Grid item xs={'auto'}>
                                        {
                                            width > 700.98
                                                ? <AusweisFront style={{ width: '100%', maxWidth: "130px", height: 'auto' }} />
                                                : <AusweisFront style={{ width: '100%', height: 'auto' }} />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileNameStyle>
                                            {FileName}
                                        </FileNameStyle>
                                        <FileSizeStyle>
                                            {FileSize}
                                        </FileSizeStyle>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Remove style={{ cursor: 'pointer' }} onClick={() => { setFileName('') }}>
                                            <X />
                                        </Remove>
                                    </Grid>
                                </Grid>
                            </AfterUploadBox>
                            <SmallSpan>
                                Unterstützte Formate: png, jpg
                            </SmallSpan>
                        </>
                    )
            }

        </InputContainer >
    )
})

export default FileUploadInputAuswies