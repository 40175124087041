import React from 'react';
import styled from 'styled-components'
import { forwardRef } from 'react';

const Wrapper = styled.input`
    border: 1px solid #DADEE4;
    width: 100%;
    border-radius: 45px;
    padding: 10px 25px;
    outline: none;
`

const InputContainer = styled.div`
    input::placeholder {
        color: #000;
        opacity: 0.4;
        font-size: 14px;
        font-weight: 400;
    }

    input:valid {
        border: 1px solid #DADEE4;
    }
/*     
    input:focus:invalid {
        border: 1px solid #F43F5E;
    } */
`

const LabelStyle = styled.div`
    font-size: 12px;
    color: #000;
    font-weight: 400;
    padding-bottom: 5px;
`

const PrimaryInput = forwardRef(function ({ hideInvalidMessage, placeholder, value, autoComplete, type, required, min, max, name, style, label, onBlur, onFocus, onChange, defaultValue, onKeyDownCapture, pattern }, ref) {

    return (
        <InputContainer>
            {
                label && (
                    <LabelStyle>
                        {label}
                    </LabelStyle>
                )
            }
            <Wrapper
                style={{ ...style }}
                placeholder={placeholder}
                autoComplete={autoComplete}
                value={value}
                defaultValue={defaultValue}
                type={type}
                ref={ref}
                required={required}
                min={min}
                max={max}
                name={name}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDownCapture={onKeyDownCapture}
                pattern={pattern}
                onInvalid={e => { hideInvalidMessage && e.target.setCustomValidity(' ') }}
                onInput={e => { e.target.setCustomValidity('') }}
                onKeyDown={(ev) => { if (ev.key === 'Enter') ev.preventDefault(); return }}
                onKeyUp={(ev) => { if (ev.key === 'Enter') ev.preventDefault(); return }}
                onKeyUpCapture={(ev) => { if (ev.key === 'Enter') ev.preventDefault(); return }}
            />
        </InputContainer>
    )
})

export default PrimaryInput