import React, { useEffect, useState } from 'react';
import CustomRadioButton from '../../../components/CustomRadioButton';
import PLZInput from '../../../components/PLZInput';
import axios from 'axios';
import { ReactComponent as MaleSvg } from '../../../assets/images/chat/maleCheckBoxIcon.svg';
import { ReactComponent as FemaleSvg } from '../../../assets/images/chat/femaleCheckBoxIcon.svg';
import { Grid, ButtonBase } from '@mui/material';
import styled from 'styled-components';
import PrimaryInput from '../../../components/PrimaryInput';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PersonTab from '../../../components/PersonTab';
import { ReactComponent as Delete } from "../../../assets/images/chat/delete.svg";

const Title = styled.p`
    font-size:18px;
    font-weight:500;
    line-height:27.9px;
    text-align:center;
`;

const Desc = styled.p`
    font-size:18px;
    font-weight:200;
    line-height:27.9px;
    text-align:center;
`;

const Accord = styled(Accordion)`
    .MuiButtonBase-root {
        border: ${({ expanded }) => (expanded ? '1px solid #49BFB3 !important' : '1px solid #050301')};
        border-radius:45px;
        width:100%;
       
        display:flex;
        flex-direction:row;
    }
    .trashBtn.MuiButtonBase-root { 
        border: none !important;
    }
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
        min-height:0px;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
        margin:12px 0px;
    }

    .css-10d1a0h-MuiButtonBase-root{
        width:100%;
        max-width:30px;
    }

    .css-11lq3yg-MuiGrid-root{
        padding-left:0px;
    }
      
    .MuiPaper-root {
        width:100%;
        max-width:700px; 
    }
`

function Step1({ reloadKey, Expanded, setExpanded, setPersonData, Step, persons, setPersons, setPersonId, personId, PersonData }) {

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPersonData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [regions, setRegions] = useState([]);

    useEffect(() => {
        //Get all the regions
        const getRegions = async () => {
            await axios.get(
                'https://node.kutiza.com/krankenkasse/regions'
            ).then(function (result) {
                setRegions(result.data);
            });
        };
        getRegions();
    }, []);


    useEffect(() => {
        if (Step > 1) {
            setExpanded(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Step]);

    const removeAccordion = (panel, id) => {
        let clone = [...persons];
        var index = clone[id].id;
        var a = clone.filter(item => item.id !== index);
        if (index !== 1) {
            setPersons(a);
            setPersonId(personId - 1)
        }
        if (Expanded === panel) {
            setExpanded(`panel1`);
        }
    };

    return (
        <>
            <div key={reloadKey}>
                <Grid container flexDirection={'column'} justifyContent={'center'}>
                    <Grid item>
                        <Title>
                            Finden Sie das beste Angebot für sich selbst
                        </Title>
                    </Grid>
                    <Grid item>
                        <Desc>
                            Füllen Sie das Formular aus und profitieren Sie von exklusiven Angeboten. Verpassen Sie nicht diese Gelegenheit mit Insualana!
                        </Desc>
                    </Grid>
                    <Grid item>
                        <Grid container pt={5} columnSpacing={2} rowSpacing={3} alignItems={'flex-end'} justifyContent={'center'}>
                            <Grid item>
                                {
                                    persons.map((id, index) =>
                                        <Accord
                                            className='PersonAccord'
                                            style={{ boxShadow: 'none', position: "static", paddingTop: "7px", background: 'transparent' }}
                                            key={`panel${index + 1}`}
                                            expanded={Expanded === `panel${index + 1}`}
                                            onChange={handleChange(`panel${index + 1}`)}>
                                            <Grid container style={{ width: "100%", maxWidth: "600px", paddingLeft: "10px" }} alignItems={'center'}>
                                                <Grid item xs>
                                                    <AccordionSummary
                                                        aria-controls={`panel${index + 1}bh-content`}
                                                        id={`panel${index + 1}bh-header`}
                                                    >
                                                        <PersonTab
                                                            index={index}
                                                            Expanded={Expanded === `panel${index + 1}`}
                                                            persons={persons}
                                                            setPersons={setPersons}
                                                            setExpanded={Expanded}
                                                        />
                                                    </AccordionSummary>
                                                </Grid>
                                                <Grid item xs={'auto'}>
                                                    <ButtonBase className='trashBtn' style={{ border: "none", paddingLeft: "5px", visibility: index === 0 && 'hidden' }}><Delete onClick={(e) => { e.stopPropagation(); removeAccordion(`panel${index + 1}`, index) }} /></ButtonBase>
                                                </Grid>
                                            </Grid>

                                            <AccordionDetails style={{ paddingTop: "20px", paddingRight: '30px' }}>
                                                <Grid
                                                    container
                                                    rowSpacing={1}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                >
                                                    <Grid
                                                        container
                                                        columnSpacing={2}
                                                        alignContent={'center'}
                                                    >
                                                        <Grid item xs={6} sm={6}>
                                                            <CustomRadioButton
                                                                Image={<MaleSvg />}
                                                                defaultChecked={true}
                                                                text={'Herr'}
                                                                label='Geschlecht'
                                                                padding={'10px 15px'}
                                                                onChange={handleInputChange}
                                                                value={'Male'}
                                                                name={`genderPerson${index + 1}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <CustomRadioButton
                                                                Image={<FemaleSvg />}
                                                                text={'Frau'}
                                                                padding={'10px 15px'}
                                                                onChange={handleInputChange}
                                                                value={'Female'}
                                                                name={`genderPerson${index + 1}`}
                                                                style={{ paddingTop: '22px' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container columnSpacing={2} rowSpacing={2} pt={1}>
                                                        <Grid item xs={12} sm={6}>
                                                            <PrimaryInput
                                                                onChange={handleInputChange}
                                                                type='number'
                                                                defaultValue={''}
                                                                name={`jahrgang${index + 1}`}
                                                                placeholder='1970'
                                                                required
                                                                min={1900}
                                                                max={2024}
                                                                style={{ padding: '10px 15px' }}
                                                                label={'Jahrgang'}

                                                            />
                                                        </Grid>
                                                        {index !== 1 && (
                                                            <Grid item xs={12} sm={6}>
                                                                <PLZInput index={index} label={'PLZ'} defaultValue={''} setPersonData={setPersonData} PersonData={PersonData} regions={regions} inputName={`plz${index + 1}`} />
                                                            </Grid>
                                                        )}

                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accord>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Step1