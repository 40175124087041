import React from 'react';
import { Grid } from '@mui/material';
import CustomContainer from './Container';
import insulanalogo from '../assets/images/home/logo.svg';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/getFunctionDimensions';
import { Link } from 'react-router-dom'
import HamburgerMenu from './HamburgerMenu';

const CustomText = ({ children }) => {
  return (
    <div style={{ textAlign: 'center', padding: '10px', fontWeight: "600", fontSize: "16px" }}>
      {children}
    </div>
  );
};

const NavbarWrapper = styled.div`
  position: fixed; 
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  padding-bottom: 15px;
  z-index:1000;
`

const Linked = styled.a`
  text-decoration:none;
  color:#42464D;
`

const Navbar = () => {

  const { width } = useWindowDimensions();

  return (
    <NavbarWrapper>
      <CustomContainer>
        <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={{ xs: 2 }} style={{ marginTop: "10px" }} justifyContent={`${width > 899.98 ? 'space-between' : 'space-around'}`} alignItems={'center'}>
          <Grid item xs={'auto'}>
            <div style={ {paddingRight :`${width > 899.98 ? '0px' : '120px'}`}}>
              <img src={insulanalogo} alt='insulanalogo' />
            </div>
          </Grid>
          {
            width > 899.98 ?
              <Grid item xs container columnSpacing={{ xs: 0, md: 1, xl: 2 }} justifyContent="end" alignItems="center">
                <Grid item xs={'auto'}>
                  <CustomText><Linked href='#Wieesfunktioniert'>Wie es funktioniert</Linked></CustomText>
                </Grid>
                <Grid item xs={'auto'}>
                  <CustomText><Linked href='#Lösungen'>Lösungen</Linked></CustomText>
                </Grid>
                <Grid item xs={'auto'}>
                  <CustomText><Linked href='#Überuns'>Über uns</Linked></CustomText>
                </Grid>
                {/* <Grid item xs={'auto'}>
                  <CustomText><Linked href='#Kundenbewertungen'>Kundenbewertungen</Linked></CustomText>
                </Grid> */}
                <Grid item xs={'auto'}>
                  <Link to={'/chat'}>
                    <Button variant="contained" style={{ backgroundColor: '#47BEB91C', color: '#47BEB9', boxShadow: 'none', fontWeight: 'bold', textTransform: 'none' }}>
                      Chat starten
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              : <Grid item xs={'auto'}>
                <div>
                  <HamburgerMenu />
                </div>
              </Grid>
          }
        </Grid>
      </CustomContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
