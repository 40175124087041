import { Box, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from 'signature_pad'
import styled from 'styled-components'
import { ReactComponent as Restart } from '../../../assets/images/chat/restartArrow.svg'
import CustomCheckbox from '../../../components/CustomCheckbox'
import TermsModal from '../../../components/TermsModal'

const TitleWrapper = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #050301;
    line-height:31px;
    padding-bottom: 0.5rem;
`

const ParagraphWrapper = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #050301;
    opacity:0.5;
    line-height:18px;
`

const SignatureCanvas = styled.canvas`
    position: relative;
    max-width: 578px;
    width: 100%;
    aspect-ratio: 15/6;
    background-color: #fff;
    border: 1px solid #E7EEF4;
    border-radius: 8px;
`

const ClearPen = styled.div`
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    cursor: pointer;
`

const TermsWrapper = styled.div`
    font-size: 14px;
    color:#9F9F9F;
    font-weight: 500;
`

const TermsLink = styled.a`
    color: #49BFB3;
    font-weight: 800;
`
const SignatureHiddenInput = styled.input`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 1px;
    height: 1px;
`

function Step7Signature({ insurance, setPersonData, PersonData }) {

    const CheckIfImageExists = (name) => {
        try {
            require(`../../../assets/images/insuranceImgs/${name}.svg`)
            return true
        } catch (error) {
            return false
        }
    }

    const signatureCanvasRef = useRef(null)
    const signaturePad = useRef(null)
    const [DidSign, setDidSign] = useState(false)

    useEffect(() => {

        signaturePad.current = new SignaturePad(signatureCanvasRef.current, {
            minWidth: 1,
            maxWidth: 2,
            penColor: "#000"
        })

        if (PersonData.signature) {

            signaturePad.current.fromDataURL(PersonData.signature);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        const StartDrawing = () => {
            setDidSign(true)

        }

        const EndDrawing = (e) => {
            setPersonData((prevFormData) => ({
                ...prevFormData,
                "signature": signaturePad.current.toDataURL(),
            }));
        }



        signaturePad.current.addEventListener("beginStroke", StartDrawing)
        signaturePad.current.addEventListener("endStroke", EndDrawing)

        return () => {
            signaturePad.current.removeEventListener("beginStroke", StartDrawing)
            signaturePad.current.removeEventListener("endStroke", EndDrawing)

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ClearSignature = () => {
        signaturePad.current.clear()
        setDidSign(false)
    }



    useEffect(() => {
        function resizeCanvas() {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            signatureCanvasRef.current.width = signatureCanvasRef.current.offsetWidth * ratio;
            signatureCanvasRef.current.height = signatureCanvasRef.current.offsetHeight * ratio;
            signatureCanvasRef.current.getContext("2d").scale(ratio, ratio);
        }

        window.addEventListener("resize", resizeCanvas);
        resizeCanvas();

        return () => {
            window.removeEventListener("resize", resizeCanvas);
        }
    }, [])

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [checked, setchecked] = useState(false)

    const Agree = () => {
        handleClose();
        setchecked(true);
    }


    return (
        insurance && (
            <>
                <Box textAlign={'center'} pb={3}>
                    {
                        CheckIfImageExists(insurance.name)
                            ? <img className='bigInsuranceImg'
                                src={
                                    require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                                }
                                alt={insurance.name}
                            />
                            :
                            <span className='bigInsurancePrice'>{insurance.name}</span>

                    }
                </Box>
                <Box textAlign={'center'} pb={5}>
                    <Grid container alignItems={'flex-end'} justifyContent={'center'}>
                        <div className="col-auto">
                            <Grid item xs={'auto'}>
                                <span className='bigInsurancePrice'>{insurance.price.toFixed(2)}</span>
                            </Grid>
                        </div>
                        <Grid item xs={'auto'}>
                            <span className='bigInsurancePriceCHF'>CHF</span>
                        </Grid>
                    </Grid>
                </Box>
                <Box textAlign={'center'}>
                    <TitleWrapper>
                        <span>
                            Fügen Sie Ihre Unterschrift hinzu
                        </span>
                    </TitleWrapper>
                    <ParagraphWrapper>
                        <span>
                            Klicken Sie auf das Kästchen, um Ihre eigene Signatur hinzuzufügen
                        </span>
                    </ParagraphWrapper>
                </Box>
                <Box textAlign={'center'} pt={4}>
                    <div style={{ position: 'relative' }}>
                        <SignatureCanvas ref={signatureCanvasRef}>
                        </SignatureCanvas>

                        <SignatureHiddenInput
                            name='signature'
                            type='text'
                            value={DidSign ? 'signed' : ''}
                            required
                            readOnly
                        />

                        <ClearPen onClick={ClearSignature}>
                            <Restart />
                        </ClearPen>
                    </div>
                </Box>
                <Grid container pt={4} alignItems={'center'} columnSpacing={2}>
                    <Grid item xs={'auto'}>
                        <CustomCheckbox
                            onChange={() => { setchecked(!checked) }}
                            name={'terms'}
                            required={true}
                            checked={checked}
                        />
                    </Grid>
                    <Grid item xs>
                        <TermsWrapper>
                            Indem Sie dieses Kästchen ankreuzen, bestätigen Sie, dass Sie die&nbsp;
                            <button type='button' style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={handleOpen}>
                                <TermsLink>
                                    Allgemeinen Geschäftsbedingungen
                                </TermsLink>
                            </button>
                            &nbsp;gelesen, verstanden und akzeptiert haben.
                        </TermsWrapper>
                    </Grid>
                    <Grid item>
                        <TermsModal open={open} handleClose={handleClose} Agree={Agree} />
                    </Grid>
                </Grid>
            </>
        )
    )
}

export default Step7Signature