import React from 'react'
import CustomRadioButton from '../../../components/CustomRadioButton';
import { Box, Grid } from '@mui/material';

function Step4({ insurance, Step, AvailableModels, setInsuranceData, InsuranceData, Models, IsLoading }) {

    const franchiseValues = [300, 500, 1000, 1500, 2000, 2500]

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInsuranceData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const CheckIfImageExists = (name) => {
        try {
            require(`../../../assets/images/insuranceImgs/${name}.svg`)
            return true
        } catch (error) {
            return false
        }
    }

    return (
        <>
            {
                insurance && (

                    <>
                        {
                            Step >= 3 && (
                                <>
                                    <div style={{ textAlign: 'center', paddingTop: '50%', display: (Step === 3 && IsLoading) ? 'block' : 'none' }}>
                                        <div className="loadingSpinner" />
                                    </div>
                                    <div style={{ display: (Step === 3 && !IsLoading) ? 'block' : 'none' }}>

                                        <Box textAlign={'center'} pb={3}>
                                            <span className="chatFormLabel" style={{ fontSize: '14px' }}>Modell:</span>
                                        </Box>

                                        <Grid container spacing={2}>
                                            {
                                                AvailableModels.includes('TAR-BASE') && (
                                                    <Grid item xs={6}>
                                                        <CustomRadioButton required name={'tariftyp'} value={'TAR-BASE'} checked={InsuranceData.tariftyp === 'TAR-BASE'} onChange={handleInputChange} text={'Standard'} />
                                                    </Grid>
                                                )
                                            }

                                            {
                                                AvailableModels.includes('TAR-HAM') && (
                                                    <Grid item xs={6}>
                                                        <CustomRadioButton required name={'tariftyp'} value={'TAR-HAM'} checked={InsuranceData.tariftyp === 'TAR-HAM'} onChange={handleInputChange} text={'Hausarzt'} />
                                                    </Grid>
                                                )
                                            }

                                            {
                                                AvailableModels.includes('TAR-HMO') && (
                                                    <Grid item xs={6}>
                                                        <CustomRadioButton required name={'tariftyp'} value={'TAR-HMO'} checked={InsuranceData.tariftyp === 'TAR-HMO'} onChange={handleInputChange} text={'HMO'} />
                                                    </Grid>
                                                )
                                            }

                                            {
                                                AvailableModels.includes('TAR-DIV') && (
                                                    <Grid item xs={6}>
                                                        <CustomRadioButton required={true} name={'tariftyp'} value={'TAR-DIV'} checked={InsuranceData.tariftyp === 'TAR-DIV'} onChange={handleInputChange} text={'Weitere Modelle'} />
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </div>
                                </>
                            )
                        }
                        {
                            Step >= 4 && (
                                <div style={{ display: (Step === 4) ? 'block' : 'none' }}>

                                    <Box textAlign={'center'} pb={3}>
                                        <span className="chatFormLabel" style={{ fontSize: '14px' }}>Modell:</span>
                                    </Box>
                                    <Grid container spacing={2}>


                                        {
                                            // eslint-disable-next-line array-callback-return
                                            Models.map((model, index) => {

                                                if (InsuranceData.tariftyp === model.Tariftyp) {

                                                    return (
                                                        <Grid item xs={6} key={index}>
                                                            <CustomRadioButton required={true} name={'model'} value={model.Tarifbezeichnung} checked={InsuranceData.model === model.Tarifbezeichnung} onChange={handleInputChange} text={model.Tarifbezeichnung} />
                                                        </Grid>
                                                    )
                                                }
                                            })
                                        }

                                    </Grid>
                                </div>
                            )
                        }

                        {
                            Step >= 5 && (
                                <div style={{ display: (Step === 5) ? 'block' : 'none' }}>

                                    <Box pb={4} textAlign={'center'}>
                                        {
                                            CheckIfImageExists(insurance.name)
                                                ? <img className='bigInsuranceImg'
                                                    src={
                                                        require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                                                    }
                                                    alt={insurance.name}
                                                />
                                                :
                                                <span className='bigInsurancePrice'>{insurance.name}</span>

                                        }
                                        <Box pt={3}>
                                            <Grid container alignItems={'flex-end'} justifyContent={'center'}>
                                                <div className="col-auto">
                                                    <Grid item xs={'auto'}>
                                                        <span className='bigInsurancePrice'>{insurance.price.toFixed(2)}</span>
                                                    </Grid>
                                                </div>
                                                <Grid item xs={'auto'}>
                                                    <span className='bigInsurancePriceCHF'>CHF</span>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Box>
                                    <Box textAlign={'center'}>

                                        <Box pb={3}>
                                            <span className="chatFormLabel" style={{ fontSize: '14px' }}>Franchise:</span>
                                        </Box>


                                        <Grid container spacing={2}>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                franchiseValues.map((value, index) => {
                                                   
                                                    return (
                                                        <Grid item xs={4} key={index}>
                                                            <CustomRadioButton checked={InsuranceData.franchise === value.toString()} required={true} name={'franchise'} value={value} onChange={handleInputChange} text={`${value} CHF`} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>

                                    <Box textAlign={'center'} pt={4}>
                                        <Box pb={3}>
                                            <span className="chatFormLabel" style={{ fontSize: '14px' }}>Ich arbeite mehr als 8 Stunden pro Woche beim gleichen Arbeitgeber.</span>
                                        </Box>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <CustomRadioButton required={true} name={'accident'} checked={InsuranceData.accident === 'Yes'} value={'Yes'} onChange={handleInputChange} text={'Ja'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomRadioButton required={true} name={'accident'} value={'No'} checked={InsuranceData.accident === 'No'} onChange={handleInputChange} text={'Nein'} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>

                            )
                        }
                    </>
                )
            }
        </>
    )
}

export default Step4