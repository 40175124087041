import React from 'react'
import { Typography } from '@mui/material';
import '../assets/css/title.css'
import styled from 'styled-components';


const TitleWrapper = styled.div`
    p {
      line-height: 80px;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-weight: 700;
      font-size: 56px;
      text-align: left;
    }

    @media (max-width: 1199.98px) { 
        p {
            font-size: 45px;
            line-height: 54px;
        }
    }

    @media (max-width: 899.98px) { 
      p {
            font-size: 35px;
            line-height: 44px;
        }
    }

    @media (max-width: 599.98px) { 
      p {
            font-size: 32px;
            line-height: 35px;
        }
    }
`
const Title = ({ title, style, greenword }) => {
  return (
    <TitleWrapper>
      <Typography className='typo' style={{ ...style }}>
        {title}
      </Typography>
    </TitleWrapper>
  )
}

export default Title