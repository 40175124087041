import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Steps from '../layouts/chat/Steps'
import ChatText from '../components/ChatText'
import { Grid } from '@mui/material'
import PrimaryButton from '../components/PrimaryButton'
import PrimaryInput from '../components/PrimaryInput'
import styled from 'styled-components'
import { ReactComponent as BotLogo } from '../assets/images/botChatIcon.svg'
import { ReactComponent as OffersLogo } from '../assets/images/chat/offers.svg'
import useWindowDimensions from '../hooks/getFunctionDimensions'
import { ReactComponent as SubmitArrow } from '../assets/images/chat/submitArrow.svg'
import { useLocation } from 'react-router-dom'

const HeaderTab = styled.div`
    border: ${props => props.selected ? '1px solid #49C0B2' : '1px solid transparent'};
    border-radius: 15px;
    color: #050301;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    width: 165px;
    opacity: ${props => props.selected ? '1' : '0.3'};
    cursor: pointer;
`

const InputWrapper = styled.div`
    position: relative;

`

const ArrowWrapper = styled.div`
    position: absolute;
    right: 1rem;
    top: calc(50% - 14px);
`

function Chat() {
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname === '/') {
            document.body.style.backgroundColor = "#fff"
        }
        else {
            document.body.style.backgroundColor = "#FBFBFB"

        }

    }, [pathname])

    const chatRef = useRef(null)

    const [Thread, setThread] = useState('')

    useEffect(() => {
        const GetThread = async () => {
            await axios.get('https://node.insulana.ch/createThread').then(response => {

                localStorage.setItem('thread', response.data.thread)
                setThread(response.data.thread)

            });
        }

        if (localStorage.getItem("thread") === null) {
            GetThread()
        }

        else {
            setThread(localStorage.getItem("thread"))
        }

    }, [])

    const [chatHistory, setchatHistory] = useState([])

    const inputRef = useRef(null)

    const submitMessage = async (event) => {
        event.preventDefault();

        var message = {
            bot: false,
            text: inputRef.current.value,
        };

        if (message.text.length > 1) {

            inputRef.current.value = ""
            setchatHistory([...chatHistory, message]);
            GetBotResponse(message)

        }
    }

    const [IsChatShown, setIsChatShown] = useState(true)

    const [Typing, setTyping] = useState(false)

    const GetBotResponse = async (message) => {

        setTyping(true)

        await axios.post('https://node.insulana.ch/createQuestion', {
            question: message.text,
            thread: Thread

        }).then((res) => {


            let msg = {
                bot: true,
                text: res.data.messages.data[0].content[0].text.value
            };

            setchatHistory([...chatHistory, message, msg]);
            setTyping(false)

        })
    }

    useEffect(() => {

        chatRef.current?.scrollIntoView({ behavior: 'smooth' });

    }, [chatHistory]);

    const [SelectedInsurance, setSelectedInsurance] = useState()

    const handleTabClick = (isChatShown) => {
        setIsChatShown(isChatShown);

    };


    const { width } = useWindowDimensions()
    return (
        <>
            <Grid container justifyContent={'center'} pt={3} pb={3}>
                <Grid item>
                    <HeaderTab selected={IsChatShown} onClick={() => { setIsChatShown(true); handleTabClick(true) }}>
                        <Grid container alignItems={'center'} columnSpacing={1}>
                            <Grid item xs={'auto'}>
                                <BotLogo />
                            </Grid>
                            <Grid item xs>
                                ChatBot
                            </Grid>
                        </Grid>
                    </HeaderTab>
                </Grid>
                <Grid item>
                    <HeaderTab selected={!IsChatShown} onClick={() => { setIsChatShown(false); handleTabClick(false) }}>
                        <Grid container alignItems={'center'} columnSpacing={1}>
                            <Grid item xs={'auto'}>
                                <OffersLogo style={{ idth: "100px" }} />
                            </Grid>
                            <Grid item xs>
                                Offerte
                            </Grid>
                        </Grid>
                    </HeaderTab>
                </Grid>
            </Grid>
          
            <div className="greenBlobBg2 chatGreenBlob" style={{ display: IsChatShown ? 'block' : 'none' }}>
                <div className="container-xl px-0 chatWrapper">
                    <div className="chatContainer px-4 px-lg-5 pt-5" id="chatDisp">
                        <ChatText
                            isBot={true}
                            text={'👋 Hallo, mein Name ist Insulana und ich würde Ihnen gerne beim Kauf Ihrer Krankenversicherung behilflich sein. Lassen Sie uns dies Schritt für Schritt angehen.'}
                        />
                        <ChatText
                            isBot={true}
                            text={'Wie kann ich Ihnen helfen?'}
                        />
                        {
                            chatHistory && (
                                chatHistory.map((chat, index) => {
                                    return (
                                        <div key={index}>

                                            <ChatText
                                                isBot={chat.bot}
                                                text={chat.text}
                                            />
                                        </div>
                                    )
                                })
                            )
                        }

                        {
                            Typing && (

                                <ChatText
                                    isTyping={true}
                                    isBot={true}
                                />
                            )
                        }



                        <div ref={chatRef} />

                    </div>
                    <div className="px-4 px-lg-5">
                        <form action="" className="mb-0" onSubmit={submitMessage}>

                            <Grid container alignItems={'center'} columnSpacing={2} rowSpacing={{ xs: 2, sm: 0 }}>
                                <Grid item xs={12} sm={9}>
                                    <InputWrapper>
                                        <PrimaryInput
                                            type={'text'}
                                            autoComplete={'off'}
                                            ref={inputRef}
                                            placeholder={"Etwas fragen"}
                                        />
                                        {
                                            (width < 600) && (
                                                <ArrowWrapper onClick={submitMessage}>
                                                    <SubmitArrow />
                                                </ArrowWrapper>
                                            )
                                        }
                                    </InputWrapper>
                                </Grid>
                                {
                                    (width > 600) && (
                                        <Grid item sm={3} xs={12} >
                                            <PrimaryButton type={'Submit'}>
                                                Einreichen
                                            </PrimaryButton>
                                        </Grid>
                                    )
                                }

                            </Grid>

                        </form>
                    </div>
                </div>
            </div >
            <div style={{ display: IsChatShown ? 'none' : 'block' }}>
                <Steps setSelectedInsurance={setSelectedInsurance} SelectedInsurance={SelectedInsurance} setIsChatShown={setIsChatShown}/>
            </div>
           

        </>
    )
}

export default Chat