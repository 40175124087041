import React from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as ClientLogo } from '../assets/images/clientChatIcon.svg';
import { ReactComponent as BotLogo } from '../assets/images/botChatIcon.svg';
import styled from 'styled-components';

const Message = styled.div`
    .client {
        color: #050301;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        border: 1px solid #E2E8F0;
        padding: 1rem 1.5rem;
        border-radius: 14px;
        max-width: 500px;
    }
    
    .bot {
        background: #49C0B212;
        border-radius: 14px;
        color: #000;
        border: 1px solid transparent;
    }
`;

function ChatText({ isBot, text, isTyping }) {
    return (
        <Grid container columnSpacing={2} pb={4} flexDirection={isBot ? 'row' : 'row-reverse'} justifyContent={'flex-start'}>
            <Grid item pt={1} xs={'auto'}>
                {isBot ? <BotLogo /> : <ClientLogo />}
            </Grid>
            <Grid item xs sm={'auto'}>
                <Message>
                    <div className={`client ${isBot ? 'bot' : ''}`}>
                        {isTyping ? <div className="dot-typing" /> : <span>{text}</span>}
                    </div>
                </Message>
            </Grid>
        </Grid>
    );
}

export default ChatText;
