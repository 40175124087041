import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CheckBoxWrapper = styled.div`
    border-radius: 45px;
    border: 1px solid #DDEBE8;
    background: #FFF;
    text-align: center;
    padding:  ${props => props.centerLabel ? '25px 60px' : '15px 15px'};
    color: #979797;
    font-size: 15px; 
    width: 100%;
    line-height:17px;
`
const CHF = styled.span`
    font-size:11px;
`
const LabelWrapper = styled.label`
    width: 100%;
    cursor: pointer;
    position: relative;
    
    input {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;

    }

    input:focus:invalid ~ .radioBorder {
        border: 1px solid #F43F5E;
    }

    input:checked ~ .radioBorder svg path {
        fill: #49bfa9;
    }

    input:checked ~ .radioBorder {
        border: ${props => props.$centerLabel ? '1px solid #49bfa9' : ' 1px solid #49bfa9'};
        color: ${props => props.$centerLabel ? '#49bfa9' : ' #49bfa9'};
        border: ${props => props.defaultChecked ? '1px solid #000' : ' 1px solid #49bfa9'};
        color: ${props => props.defaultChecked ? '' : ' #49bfa9'};
    }
    input:checked ~ .radioBorder span {
        font-weight: 600;
    }
`

const LabelStyle = styled.div`
    font-size:  ${props => props.$centerLabel ? '20px' : '12px'};
    color:#000 ;
    font-weight: 400;
    padding-bottom: 5px;
    opacity: ${props => props.$centerLabel ? '0.5' : '1'};
    display:flex;
    justify-content: ${props => props.$centerLabel ? 'center' : 'flex-start'};
`

const StyledGrid = styled(Grid)`
    display: inline-flex;
    align-items: center;
`



function CustomRadioButton({ onChange, name, value, text, Image, label, style, padding, centerLabel = false, chf, defaultChecked = undefined, required,checked = undefined }) {

    const ClearValidation = () => {
        var inputs = document.querySelectorAll(`input[name="${name}"]`)

        for (let i = 0; i < inputs.length; i++) {
            inputs[i].setCustomValidity('');
        }
    }

    return (
        <LabelWrapper style={{ ...style }} $centerLabel={centerLabel} onClick={ClearValidation}>
            <input
                required={required}
                type="radio"
                name={name}
                value={value}
                onChange={onChange}
                defaultChecked={defaultChecked}
                checked={checked}
                onInvalid={e => { e.target.setCustomValidity(' ') }}
                onInput={e => { e.target.setCustomValidity('') }}

            />
            {label && (
                <LabelStyle $centerLabel={centerLabel}>
                    {label}
                </LabelStyle>
            )}
            <CheckBoxWrapper className='radioBorder' style={{ padding: padding }} $centerLabel={centerLabel}>
                <StyledGrid container alignItems={'center'} justifyContent={'center'} columnSpacing={1}>
                    <Grid item xs={'auto'}>
                        {Image}
                    </Grid>
                    <Grid item xs={'auto'}>
                        <span>{text} <CHF>{chf}</CHF></span>
                    </Grid>
                </StyledGrid>
            </CheckBoxWrapper>
        </LabelWrapper>
    )
}


export default CustomRadioButton
