import { Box, Grid } from '@mui/material'
import React from 'react'
import PrimaryInput from '../../../components/PrimaryInput'
import CustomSelectInput from '../../../components/CustomSelectInput'
import PhoneInput from '../../../components/PhoneInput'

function Step6({ insurance, setPersonData, InsuranceData, PersonData }) {

  const CheckIfImageExists = (name) => {
    try {
      require(`../../../assets/images/insuranceImgs/${name}.svg`)
      return true
    } catch (error) {
      return false
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    insurance && (
      <>
        <Box textAlign={'center'} pb={3}>
          {
            CheckIfImageExists(insurance.name)
              ? <img className='bigInsuranceImg'
                src={
                  require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                }
                alt={insurance.name}
              />
              :
              <span className='bigInsurancePrice'>{insurance.name}</span>

          }
        </Box>
        <Box textAlign={'center'} pb={5}>
          <Grid container alignItems={'flex-end'} justifyContent={'center'}>
            <div className="col-auto">
              <Grid item xs={'auto'}>
                <span className='bigInsurancePrice'>{insurance.price.toFixed(2)}</span>
              </Grid>
            </div>
            <Grid item xs={'auto'}>
              <span className='bigInsurancePriceCHF'>CHF</span>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              value={PersonData.full_name}
              type='text'
              name={`full_name`}
              placeholder='Manfred Mustermann'
              required
              style={{ padding: '10px 15px' }}
              label={'Full Name'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='text'
              name={`addresse`}
              placeholder='3014 Bern'
              value={PersonData.addresse}
              required
              style={{ padding: '10px 15px' }}
              label={'⁠Adresse/PLZ'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput handleInputChange={handleInputChange} PersonData={PersonData} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='email'
              name={`email`}
              value={PersonData.email}
              placeholder='name@domain.com'
              required
              style={{ padding: '10px 15px' }}
              label={'Email'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='text'
              name={`model`}
              value={InsuranceData.model}
              placeholder='Grundversicherung'
              required
              style={{ padding: '10px 15px' }}
              label={'Model --> Krankenkasse'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='number'
              name={`franchise`}
              value={InsuranceData.franchise}
              placeholder='2500'
              required
              style={{ padding: '10px 15px' }}
              label={'Franchise'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelectInput 
              onChange={handleInputChange}
              name={`nationalitat`}
              label={'Nationalität'}
              value={PersonData.nationalitat}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='text'
              name={`arbeit`}
              value={PersonData.arbeit}
              placeholder='Arbeitslos'
              required
              style={{ padding: '10px 15px' }}
              label={'Arbeitsverhältnis (arbeitslos, angestellt)'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='text'
              name={`iban`}
              value={PersonData.iban}
              placeholder='CH5604835012345678009'
              required
              style={{ padding: '10px 15px' }}
              label={'IBAN'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PrimaryInput
              onChange={handleInputChange}
              type='text'
              name={`zahlungsart`}
              value={PersonData.zahlungsart}
              placeholder='Kreditkarte'
              required
              style={{ padding: '10px 15px' }}
              label={'Zahlungsart'}
            />
          </Grid>
          
        </Grid>
      </>

    )
  )
}

export default Step6