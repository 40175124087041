import React from 'react'
import SectionBreaker from '../../components/SectionBreaker'
import { ReactComponent as Person1 } from '../../assets/images/home/commentperson1.svg'
import { ReactComponent as Person2 } from '../../assets/images/home/commentperson2.svg'
import { Box } from '@mui/material'
// import Title from '../../components/Title'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import '../../Style/style.css';
import SliderCard from '../../components/SliderCard'
import styled from 'styled-components'
import Title from '../../components/Title'
import CustomContainer from '../../components/Container'
import useWindowDimensions from '../../hooks/getFunctionDimensions'

const Greentext = styled.p`
  padding:10px 0;
  font-size: 18px;
  font-weight: 600;
  lin-height:18px;
  color: #47BEB9;
  text-align:left;
`;

const SectionEight = () => {

  const { width } = useWindowDimensions();
  return (
    <SectionBreaker>
      <CustomContainer>
        <Box pb={3} >
          <Greentext>PARTNERS</Greentext>
          <Title style={{ width: "100%", maxWidth: "700px" }} title={'Hören Sie von unseren zufriedenen Nutzern'} />
        </Box>
      </CustomContainer>
      {width > 899.99 ? (
        <Box style={{ maxWidth: width < 1450 ? "1200px" : '1500px', margin: 'auto' }}>
          <Swiper
            spaceBetween={0}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 2,
                spaceBetween: 0,
              }
            }}
            className="testimonialSwiper"
          >
            <SwiperSlide>
              <SliderCard
                comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
                name={'Pat Flynn'}
                desc={'Cardiology Patient'}
                image={<Person1 />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SliderCard
                comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
                name={'Melissa Tal'}
                desc={'Cardiology Patient'}
                image={<Person2 />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SliderCard
                comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
                name={'Pat Flynn'}
                desc={'Cardiology Patient'}
                image={<Person1 />}
              />
            </SwiperSlide>
          </Swiper>
        </Box>
      ) : (<Box style={{ maxWidth: '1500px', margin: 'auto' }}>
        <Swiper
          spaceBetween={0}
          pagination={true}
          centeredSlides={true}
          breakpoints={{
            0: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            600: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
            1440: {
              slidesPerView: 2,
              spaceBetween: 0,
            }
          }}
          className="testimonialSwiper"
        >
          <SwiperSlide>
            <SliderCard
              comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
              name={'Pat Flynn'}
              desc={'Cardiology Patient'}
              image={<Person1 />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard
              comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
              name={'Melissa Tal'}
              desc={'Cardiology Patient'}
              image={<Person2 />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SliderCard
              comment={"Lorem ipsum dolor sit amet consectetur. Platea aliquam diam lorem dictum at aliquam. Non odio volutpat eu massa duis lorem id. Tristique gravida."}
              name={'Pat Flynn'}
              desc={'Cardiology Patient'}
              image={<Person1 />}
            />
          </SwiperSlide>
        </Swiper>
      </Box>)}


    </SectionBreaker>
  )
}

export default SectionEight