import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Title = styled.div`
    font-size:16px;
    font-weight:700;
    line-height:30px;

    @media (max-width: 899.98px) {
        font-size:14px;
    }

    @media (max-width: 599.98px) { 
        font-size:14px;
    }
`
const Desc = styled.div`
    font-size:14px;
    font-weight:300;
    line-height:31px;
    width:100%;
    max-width:500px;

    @media (max-width: 899.98px) {
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
    }
`

const SectionField = ({ svg, title, desc }) => {
    return (
        <Grid container flexDirection={"column"} rowSpacing={1}>
            <Grid item>
                <Grid container columnSpacing={1} alignItems={"center"}>
                    <Grid item>
                        {svg}
                    </Grid>
                    <Grid item>
                        <Title>
                            {title}
                        </Title>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Desc>
                    {desc}
                </Desc>
            </Grid>
        </Grid>
    )
}

export default SectionField