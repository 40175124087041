import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CardWrapper = styled(Grid)`
    box-shadow: 0px 5px 32px 0px rgba(22, 33, 77, 0.08);
    width:100%;
    max-width:600.66px;
    padding:20px 25px;
    border-radius:10px;

    @media (max-width: 1440px) { 
        max-width:460px;
        padding:18px 22px;
    }

    @media (max-width: 899.98px) {
        max-width:300.66px;

    }

    @media (max-width: 599.98px) { 
        max-width:300.66px;

    }
`

const Comment = styled.span`
    display:block;
    font-size:16px;
    line-height:29px;
    font-weight:400;
    text-align:left;

    @media (max-width: 1440px) { 
        font-size:14px;
    }

    @media (max-width: 899.98px) {
        font-size:12px;
    }

    @media (max-width: 599.98px) { 
        font-size:12px;
    }
`

const Name = styled.span`
    display:block;
    font-size:18px;
    line-height:20px;
    font-weight:400;
    text-align:left;
    color:#49C0B3;

    @media (max-width: 1440px) { 
        font-size:15px;
    }

    @media (max-width: 899.98px) {
        font-size:15px;
    }

    @media (max-width: 599.98px) { 
        font-size:15px;
    }
`

const Desc = styled.span`
    display:block;
    font-size:14px;
    line-height:21px;
    font-weight:400;
    text-align:left;
    color:#545861;

    @media (max-width: 899.98px) {
        font-size:10px;
    }

    @media (max-width: 599.98px) { 
        font-size:10px;
    }
`

const SliderCard = ({ comment, name, desc, image }) => {
    return (
        <CardWrapper container justifyContent={'center'} flexDirection={'column'} rowSpacing={3}>
            <Grid item xs={'auto'}>
                <Comment>
                    {comment}
                </Comment>
            </Grid>
            <Grid item xs={'auto'}>
                <Grid container columnSpacing={1}  alignItems={"center"}>
                    <Grid item xs={2}>
                        {image}
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container flexDirection={'column'}>
                            <Grid item>
                                <Name>
                                    {name}
                                </Name>
                            </Grid>
                            <Grid item>
                                <Desc>
                                    {desc}
                                </Desc>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </CardWrapper>
    )
}

export default SliderCard