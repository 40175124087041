import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Section from '../layouts/home/Section'
import SectionTwo from '../layouts/home/SectionTwo'
import SectionThree from '../layouts/home/SectionThree'
import SectionFive from '../layouts/home/SectionFive'
import SectionEight from '../layouts/home/SectionEight'
import Footer from '../components/Footer'
import { useLocation } from 'react-router-dom'

function Home() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/') {
      document.body.style.backgroundColor = "#fff"
    }
    else {
      document.body.style.backgroundColor = "#FBFBFB"

    }

  }, [pathname])

  return (
    <>
      <Navbar />
      <Section />
      <div id="Wieesfunktioniert">
        <SectionTwo />
      </div>
      <div id="Lösungen">
        <SectionThree />
      </div>
      <div id="Überuns">
        <SectionFive />
      </div>
      <div id="Kundenbewertungen">
        <SectionEight />
      </div>
      <Footer />
    </>
  )
}

export default Home