import React from 'react';
import { Box, Grid } from '@mui/material'
import { ReactComponent as Greencheckpoint } from '../../../assets/images/home/greencheckpoint.svg';
import styled from 'styled-components'


const Wrapper = styled.div`
    border: 1px solid #E7EEF4;
    border-radius: 26px;
    background-color: #fff;

`

const Points = styled.span`
    font-weight:500;
`

const ImageWrapperBorder = styled(Box)`
    border-bottom: 1px solid #E7EEF4; 
    width: 100%;

`

function Step3({ insurance, InsuranceData }) {

    const CheckIfImageExists = (name) => {
        try {
            require(`../../../assets/images/insuranceImgs/${name}.svg`)
            return true
        } catch (error) {
            return false
        }
    }

    return (

        insurance && (
            <Wrapper>

                <Grid container flexDirection={'column'} justifyContent={'center'}>
                    <Grid item xs>
                        <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} padding={0}>
                            <ImageWrapperBorder py={5}>
                                <Grid item textAlign={'center'}>

                                    <div className=''>
                                        {
                                            CheckIfImageExists(insurance.name)
                                                ? <img className='bigInsuranceImg'
                                                    src={
                                                        require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                                                    }
                                                    alt={insurance.name}
                                                />
                                                :
                                                <span className='bigInsurancePrice'>{insurance.name}</span>

                                        }

                                    </div>


                                </Grid>
                            </ImageWrapperBorder>

                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container py={5} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} rowSpacing={3}>
                            <Grid item xs>
                                <Grid container alignItems={'flex-end'} justifyContent={'center'}>
                                    <div className="col-auto">
                                        <Grid item xs={'auto'}>
                                            <span className='bigInsurancePrice'>{insurance.price.toFixed(2)}</span>
                                        </Grid>
                                    </div>
                                    <Grid item xs={'auto'}>
                                        <span className='bigInsurancePriceCHF'>CHF</span>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item>
                                <Grid container flexDirection={'column'} justifyContent={'start'} alignItems={'start'} rowSpacing={2}>
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={'auto'} style={{ lineHeight: 1 }}>
                                                <Greencheckpoint />
                                            </Grid>
                                            <Grid item style={{ lineHeight: 1 }}>
                                                <Points>Modell: {InsuranceData.model.split('%')[0]}</Points>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={'auto'} style={{ lineHeight: 1 }}>
                                                <Greencheckpoint />
                                            </Grid>
                                            <Grid item style={{ lineHeight: 1 }}>
                                                <Points>Franchise: {InsuranceData.franchise} CHF</Points>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={'auto'} style={{ lineHeight: 1 }}>
                                                <Greencheckpoint />
                                            </Grid>
                                            <Grid item style={{ lineHeight: 1 }}>
                                                <Points>Unfall: {InsuranceData.accident === 'Yes' ? 'No' : 'Yes'}</Points>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Wrapper>
        )

    )
}

export default Step3