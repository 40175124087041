import React from 'react'
import useWindowDimensions from '../hooks/getFunctionDimensions'

const ChatSlider = ({svg}) => {
  const {width} = useWindowDimensions();

  return (
    <div style={{ width: (width < 899.99 ? '80%': '100%'), height: (width < 899.99 ? '80%': '100%'), alignItems:"center" }}>{svg}</div>
  )
}

export default ChatSlider