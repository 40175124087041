import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Error } from '../assets/images/chat/errorIcon.svg'

const Wrapper = styled.div`
    border: 1px solid #DEE2E6;
    border-radius: 9px;
    padding: 1rem;
    width: max-content;
    max-width: 500px;
    background-color: #fff;
    margin: auto;
`
const Description = styled.div`
    color: #333333B2;
    font-size: 14px;
`

function ErrorBox() {
    return (
        <Wrapper>
            <Grid container alignItems={'center'} columnSpacing={2}>
                <Grid item xs={'auto'}>
                    <Error />
                </Grid>
                <Grid item xs>
                    <Description>
                        <span>Bitte füllen Sie alle Eingaben aus</span>
                    </Description>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default ErrorBox