import React from 'react'
import CustomContainer from '../../components/Container'
import SectionBreaker from '../../components/SectionBreaker';
import { Box, Grid } from '@mui/material';
import useWindowDimensions from '../../hooks/getFunctionDimensions';
import { ReactComponent as VisanaLogo } from '../../assets/images/home/visanalogo.svg';
import { ReactComponent as CSSLogo } from '../../assets/images/home/csslogo.svg';
import { ReactComponent as AssuraLogo } from '../../assets/images/home/assuralogo.svg';
import { ReactComponent as GrupemutuelLogo } from '../../assets/images/home/grupemutuellogo.svg';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import '../../Style/style.css';

const Typo = styled.div`
  font-size:19.84px;
  font-weight:400;
  line-height:32px;
  text-align:center;
  color:#42464D;

  @media (max-width: 899.98px) {
    font-size: 14px;
  }

  @media (max-width: 599.98px) { 
    font-size: 14px;
  }
`
const SectionThree = () => {

  const { width } = useWindowDimensions();

  return (
    <SectionBreaker>
      <CustomContainer>
        <Grid container justifyContent={"center"} rowSpacing={4}>
          <Grid item>
            <Grid container>
              {width > 899.99 ? (
                <Box style={{ maxWidth: '1200px', margin: 'auto' }}>
                  <Swiper
                    spaceBetween={0}
                    pagination={true}
                    breakpoints={{
                      1000: {
                        slidesPerView: 3,
                        spaceBetween: 1,
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 1,
                      },
                      1440: {
                        slidesPerView: 6,
                        spaceBetween: 1,
                      }
                    }}
                    className="insuranceSwiper"
                  >
                    <SwiperSlide>
                      <CSSLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <VisanaLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AssuraLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <CSSLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <GrupemutuelLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AssuraLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                  </Swiper>
                </Box>
              ) : (
                <Box style={{ maxWidth: '350px', margin: 'auto' }}>
                  <Swiper
                    spaceBetween={0}
                    pagination={true}
                    breakpoints={{
                      0: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      600: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      800: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      }
                    }}
                    className="insuranceSwiper"
                  >
                    <SwiperSlide>
                      <CSSLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <VisanaLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AssuraLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <CSSLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <GrupemutuelLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AssuraLogo style={{ maxWidth: "100px", height: "auto" }} />
                    </SwiperSlide>
                  </Swiper>
                </Box>)}
            </Grid>
          </Grid>
          <Grid item>
            <Typo>More than 8,000 creators and businesses across the world trust Circle with their community.</Typo>
          </Grid>
        </Grid>
      </CustomContainer>
    </SectionBreaker>
  )
}

export default SectionThree