import React, { useState } from 'react'
import { ReactComponent as Basic } from '../../../assets/images/insuranceImgs/basic.svg'
import { ReactComponent as Supplementary } from '../../../assets/images/insuranceImgs/supplementary.svg'
import { ReactComponent as CommingSoon } from '../../../assets/images/chat/comingsoon.svg'
import { Grid } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';

const TapsContainer = styled.div`
  button {
    font-size: 24px;
  }

  .MuiTabs-indicator {
    background-color: #49BFB3;
  }
  .MuiButtonBase-root {
    color: #717680 !important;
  }
  .Mui-selected {
    color: #191B1F !important;
  }
  @media (max-width: 1199.98px) { 
    button {
      font-size: 22px;
    }
  }

  @media (max-width: 899.98px) {
    button {
      font-size: 20px;
    }
  }

  @media (max-width: 599.98px) { 
    button {
      font-size: 16px; 
      padding: 12px 9px;
    }
  }
`

const TabsText = styled.span`
  font-size:16px;
  font-weight:500;
  line-height:22px;
  text-align:center;
  @media (max-width: 599.98px) { 
    font-size:14px;
  }
`

const TabSelected = styled(Tab)`
    color: ${props => props.selected ? "#191B1F" : "#717680"};
    opacity: ${props => props.selected ? '1' : '0.5'};
    font-weight: ${props => props.selected ? '700' : '400'};
    padding: 10px 50px;
    svg {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 599.98px) { 
      svg {
        width: 30px;
        height: 30px;
        margin-right: 5px !important;
      }
    }
    
`

const InsuranceWrapper = styled.div`
    width: 100%;
    height:56px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border:  ${props => props.selected ? '1px solid #49BFB3' : '1px solid #F0F0F0'}; 
    border-radius:42px;
    cursor:pointer;
`;

const Price = styled.span`
  font-size:15px;
  font-weight:400;
  line-height:14px;
  letter-spacing:0.25px;
  padding-top:30px;
  color:${props => props.selected ? '#49BFB3' : '#9B9A99'};
  font-weight:${props => props.selected ? '600' : '400'};

`;

const CH = styled.span`
  font-size:15px;
  line-height:14px;
  letter-spacing:0.25px;
  color:${props => props.selected ? '#49BFB3' : '#9B9A99'};
  font-weight:${props => props.selected ? '600' : '400'};
`

const InsuranceWrapperLabel = styled.label`
  position: relative;

  input {
    position: absolute;
    top: 50%;
    left: 50%; 
    z-index: -1;
  }

  input:focus:invalid ~ ${InsuranceWrapper}  {
    border: 1px solid #F43F5E;
  }
`

function Step2({ Insurances, setInsuranceAndPrice, Step, InsuranceAndPrice }) {
  const [IsBasic, setIsBasic] = useState(true);

  const [selectedInsurance, setSelectedInsurance] = useState(null);

  const handleToggleSelect = (insurance) => {
    setSelectedInsurance(insurance);
  };

  const CheckIfImageExists = (name) => {
    try {
      require(`../../../assets/images/insuranceImgs/${name}.svg`)

      return true
    } catch (error) {
      return false
    }
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ClearValidation = () => {
    var inputs = document.querySelectorAll('input[name="price"]')

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].setCustomValidity('');
    }
  }

  return (
    <>
      <div className=''>
        <div className="">
          <div className="row gx-2 gx-lg-3 text-center">
            <Grid container justifyContent={'center'}>
              <Grid item>
                <TapsContainer>
                  <Tabs style={{ margin: "30px 0" }} value={value} onChange={handleChange} variant="scrollable">
                    <TabSelected selected={IsBasic} onClick={() => { setIsBasic(true) }} style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Basic style={{ marginRight: '10px' }} />
                          <TabsText>Grundversicherung</TabsText>
                        </div>
                      }
                    />

                    <TabSelected onClick={() => { setIsBasic(false) }} style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Supplementary style={{ marginRight: '10px' }} />
                          <TabsText>Zuzatversicherung</TabsText>
                        </div>
                      }
                    />
                  </Tabs>
                </TapsContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Grid container rowSpacing={1}>
        {
          IsBasic ? (
            Insurances && (
              Insurances.final_data.slice(0, 7).map((insurance, index) =>

                <Grid item xs={12} key={index}>
                  <InsuranceWrapperLabel className="w-100">
                    <input
                      id={insurance.id}
                      required={Step === 2}
                      type="radio"
                      name='price'
                      value={`Basic%${insurance.id}%${insurance.tarif}`}
                      onChange={(e) => { setInsuranceAndPrice(e.target.value) }}
                      onInvalid={e => { e.target.setCustomValidity(' ') }}
                      onClick={ClearValidation}
                      checked={InsuranceAndPrice === `Basic%${insurance.id}%${insurance.tarif}`}
                    />

                    <InsuranceWrapper selected={selectedInsurance === insurance || InsuranceAndPrice === `Basic%${insurance.id}%${insurance.tarif}`} // Check if this insurance is selected
                      onClick={() => handleToggleSelect(insurance)} >
                      <Grid container justifyContent={"space-around"} alignItems={'center'}>
                        <Grid item xs={3}>
                          <div style={{ textAlign: 'center' }}>
                            {
                              CheckIfImageExists(insurance.name)
                                ?
                                <img
                                  src={
                                    require(`../../../assets/images/insuranceImgs/${(insurance.name === "ÖKK") ? 'OKK' : insurance.name}.svg`)
                                  }
                                  alt={insurance.name}
                                  className='insuranceImg'
                                  style={{ maxWidth: 'unset', height: 'auto' }}
                                />
                                :

                                <span className="chatFormLabel" style={{ fontSize: '20px' }}>
                                  {insurance.name}
                                </span>
                            }
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <Price selected={selectedInsurance === insurance || InsuranceAndPrice === `Basic%${insurance.id}%${insurance.tarif}`}
                            onClick={() => handleToggleSelect(insurance)}>
                            {insurance.price.toFixed(2)}
                            <CH selected={selectedInsurance === insurance || InsuranceAndPrice === `Basic%${insurance.id}%${insurance.tarif}`}
                              onClick={() => handleToggleSelect(insurance)}>CHF</CH>
                          </Price>
                        </Grid>
                      </Grid>
                    </InsuranceWrapper>
                  </InsuranceWrapperLabel>
                </Grid>
              )
            )
          )
            :
            (
              <Grid container justifyContent={'center'} alignItems={'center'} pt={5} pb={5}>
                <Grid item>
                  <CommingSoon />
                </Grid>
              </Grid>

            )
        }

      </Grid>

    </>
  )
}

export default Step2