import { Grid } from '@mui/material';
import React from 'react'
import { ReactComponent as DownArrow } from "../assets/images/chat/downarrow.svg"
import { ReactComponent as UpArrow } from "../assets/images/chat/uparrow.svg"
import { ReactComponent as PersonSVG } from "../assets/images/chat/person.svg"
import { ReactComponent as BlackPersonSVG } from "../assets/images/chat/blackperson.svg"
import styled from 'styled-components';

const Person = styled.span`
    color:#49BFB3;
    padding-left:10px;
`

const BlackPerson = styled.span`
    color:#050301;
    padding-left:10px;
`

function PersonTab({ index, Expanded }) {

    return (
        <Grid container justifyContent={'space-between'} alignItems="center">
            <Grid item>
                {
                    Expanded ? (<PersonSVG />) : (<BlackPersonSVG />)
                }
                {
                    Expanded ? (<Person >Persone  {index + 1}</Person>) : (<BlackPerson>Persone  {index + 1}</BlackPerson>)
                }
            </Grid>

            <Grid item>
                <Grid container>
                    {
                        !Expanded ? (<DownArrow />) : (<UpArrow />)
                    }
                </Grid>
            </Grid>
        </Grid>
    )
    
}

export default PersonTab